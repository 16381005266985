// Simulates infinite scrolling on mobile for departments and search.
// Using jQuery to avoid JS bugs on mobile with click simulation.// Cette fonction est conditionnée selon la valeur de #is_loading dans le DOM afin d'éviter un nombre trop conséquent de clics pendant la requête. Voir => generateNewBlocProd.

// The isInViewport function is used to check if the button appears on the screen. If so, the click is simulated, otherwise not. This function is called in the click condition during scrolling.
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

$(function() {
    var isclicked = false;
    var mainContainer = document.querySelector("body.category");

    if (mainContainer) {
        $(window).scroll(function() {   
            var isLoading = $('#is_loading').val();
            const maxItems = document.getElementById('totalElems').value;
            const productLoaded = $('#list_item .item_container:not(".push")').length;
            const pagerNav = document.querySelector('.pagerNav.end_pagi');
            const button = document.querySelector('.see_all_product');

            // If the first click has occurred, then the variable becomes true and allows to validate the condition below. We hide the pagination block which will only be displayed if all items are loaded, and if isInViewport returns true and isLoading is set back to 0 (see => generateNewBlocProd). Then, the click is triggered and the isLoading variable is set to 1 to block any new click before the end of the Ajax request.
            if (isclicked) {
                button.style.opacity = '1';

                if (isInViewport(button) && isLoading == 0) {
                    $('button.see_all_product').trigger('click');
                    $('#is_loading').val('1');
                }  
             }       
        });
    }

    // A boolean that allows to unlock the rest of the scroll event when clicking on the "see more" button.
    $('button.see_all_product').on('click', function() {
        if (!isclicked) {
            isclicked = true;
        }
    }); 
  });