// Login si l'utilisateur valide son code
// SPECIFIQUE TATI, A DEPLACER
function mytati_autoLogin(gencode) {

    $('#numero_carte').val(gencode);
    $('#btn_infos').click();
}

/**
 *  LEGACY
 */
function categSwitch(query) {

    var idcateg = this.value;

    query = path_relative_root + 'sitesearch_recherche.php?' + query;

    if (idcateg !== '') {

        query += '&idcateg=' + idcateg;
    }

    window.location.assign(query);
}

function show_question(elementID, fieldID, idService, parentPath) {

    var list_question = document.getElementById(elementID);
    var field = document.getElementById(fieldID);

    if (idService === "career") {

        return location.assign(path_relative_root + create_link("satellite_recrutement"));
    }

    if (list_question) {

        var response = ajax_file(parentPath + 'ajax_list_question.php?idService=' + idService);

        if (response) {

            list_question.innerHTML = response;
            field.innerHTML = "";
        }
    }
}

function show_fields(elementID, idService, idQuestion, parentPath) {

    var list_field = document.getElementById(elementID);
    if (list_field) {
        var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=' + idService + '&idQuestion=' + idQuestion);
        $(".contact-beware a").attr('href', parentPath + 'connexion.php?from=contact&id_service=' + idService + '&id_question=' + idQuestion);
        if (response) {
            list_field.innerHTML = response;

            tabError = new Array();
            tabError[0] = new Array('alpha', 'contactNom', 'contact_field_nom');
            tabError[1] = new Array('alpha', 'contactPrenom', 'contact_field_prenom');
            tabError[2] = new Array('mail', 'contactMail', 'contact_field_mail');
            tabError[3] = new Array('optin', 'wrapper_optin', 'wish_receive_info');
            tabError[4] = new Array('optin', 'wrapper_optin_sms', 'wish_receive_info');

            var i = 5;

            if (idService == 2 && idQuestion == 5) {
                tabError[i] = new Array('alphanum', 'contactRefProduit', 'contact_field_ref_produit');
                i++;
            }

            tabError[i] = new Array('no_empty', 'contactMessage', 'contact_field_message');

            if ($('#g-recaptcha').length > 0) {
                grecaptcha.render('g-recaptcha');
            }

        } else
            list_field.innerHTML = '';
    }
}

function modForm(evt) {

    var form = $(this).closest('form');

    evt.preventDefault();

    $('[readonly]', form).prop('readonly', false);

    $(this).hide();
    $(this).next('button').show();

    $('.hide', form).slideDown(200);
}

function checkScrollTop() {

    var cnt = document.getElementById('contents_wrapper'),
            btt = document.getElementById('btt_button'),
            btm = $('.site_bottom').get(0);

    if (window.innerHeight > btm.offsetTop - cnt.scrollTop) {

        var diff = window.innerHeight - (btm.offsetTop - cnt.scrollTop);

        btt.style.bottom = diff + 'px';
    } else {

        btt.style.bottom = 0;
    }
}

// Triggers page loader
function triggerLoader() {

    if (menuwrapr.classList.contains('open')) {

        toggleLeftMenu.call(document.getElementById('main_menu_btn'));
    }
}

// verifSame
function verifSame(field1, field2, fieldname) {

    var objElement = document.getElementById(field2),
            objLabel = document.getElementById(field1 + 'Label'),
            erreurText = document.getElementById(field2).nextElementSibling;

    if (document.getElementById(field1).value != objElement.value) {

        var txt_lang = (fieldname == 'login_email_verif') ? 'js_same_email_confirm' : 'js_same_mdp_confirm';

        erreurText.innerHTML = translate(fieldname) + ' : ' + translate(txt_lang, fieldname);

        $(objElement).addClass('inputErr');

        if (objLabel)
            $(objLabel).addClass('labelErr');

        return false;
    }

    $(objElement).removeClass('inputErr');

    if (objLabel)
        $(objLabel).removeClass('labelErr');

    return true;
}

function inputDisplayErr() {

    var field = $(this), error, err_content;

    if (field.hasClass('inputErr')) {

        error = field.siblings('#' + field.attr('id') + 'Err');

        if (error.length > 0) {

            err_content = error.html();

            if (err_content !== '') {

                err_content = err_content.replace(/<br(?: \/)?> ?/g, ' ');
                err_content = err_content.replace(/<\/p> ?/g, '\n');
                err_content = err_content.replace(/<.+?>/g, '');
                err_content = err_content.replace(/ {2,}/g, ' ');

                window.alert(err_content.trim());
            }
        }
    }
}

// Executes a function defined in a view
function execPageFunc(definition) {

    if (definition.func !== undefined) {

        if (definition.func.constructor !== Function) {

            definition.func = window[definition.func.toString()];
        }

        if (definition.func !== undefined) {

            if (definition.args !== undefined && Array.isArray(definition.args)) {

                definition.func.apply(window, definition.args);
            } else if (definition.args !== undefined) {

                definition.func.call(window, definition.args);
            } else {

                definition.func.call(window);
            }
        }
    }
}

// Display / hide banner search input
function toggleSearch() {

    // Cache elements so we won't call jQuery everytime we need them
    var clktarget = $(this),
            container = $(document.getElementById('top_search')),
            srchfield = $('input', container),
            shade = $(document.getElementById('general_shade')),
            menuwrapr = $(document.getElementById('main_menu')),
            acntwrapr = $(document.getElementById('account_menu'));

    if (container.is(':visible')) {
        // If the container is already visible...

        if (srchfield.val() !== '') {
            // ... and a research has been made...

            // ... reset field
            srchfield.val('').keyup();
        }

        if (srchfield.is(':focus')) {
            // ... and the user's focus is on research field...

            // ... release focus
            srchfield.blur();
        }

        // Wait 0.2s then fade out in 0.2s
        window.setTimeout(function () {

            clktarget.removeClass('active');

            document.getElementById('contents_wrapper').style.overflow = 'scroll';

            container.fadeOut(160);
            shade.fadeOut(200);
        }, 200);
    } else {
        // If the container is not visible

        // Hide the main menu if visible
        if (parseFloat($('#left_menu').css('left')) === 0) {

            toggleMenu.call(document.getElementById('main_menu_btn'));
        }

        // Hide the account menu if visible
        if (acntwrapr.is(':visible')) {

            toggleAccountMenu.call(document.getElementById('user_account_btn'));
        }

        document.getElementById('contents_wrapper').style.overflow = 'hidden';

        // Fade in in 0.2s...
        shade.fadeIn(160);
        container.fadeIn(200, function () {

            clktarget.addClass('active');

            // ... then wait 0.2s and set focus on the research field
            window.setTimeout(function () {

                srchfield.focus();
            }, 200);
        });
    }
}

// Apply new visual on image load
function onAlternativeColorLoad(loaded) {
    if (window.prod_slider !== undefined) {
        //this.src = loaded.src;
        var initialPic = $('#product_pictures_slider>li>div>img').eq(1).attr('src');

        prod_slider.reloadSlider({
            speed: prod_slider.closest('.slider_container').get(0).offsetWidth * 0.8,
            easing: 'linear',
            onSliderLoad: function (currentSlide) {
                $('#product_pictures_slider>li>div>img').removeClass('active-slide');
                $('#product_pictures_slider>li>div>img').removeAttr('id');
                $('#product_pictures_slider>li>div>img').eq(currentSlide + 1).addClass('active-slide');
                $('#product_pictures_slider>li>div>img').eq(currentSlide + 1).attr('id', 'img_large');

                //replace image when color changed
                $('#product_pictures_slider>li>div>img').eq(currentSlide + 1).attr('src', loaded.src);

                //replace image on corresponding bx-clone
                $('#product_pictures_slider li').each(function () {
                    if ($(this).attr('class').indexOf('bx-clone') > 0) {
                        if ($('img', this).attr('src') == initialPic) {
                            $('img', this).attr('src', loaded.src);
                        }
                    }
                });
            },
            onSlideBefore: function (currentSlide, totalSlides, currentSlideHtmlObject) {
                $('#product_pictures_slider>li>div>img').removeClass('active-slide');
                $('#product_pictures_slider>li>div>img').removeAttr('id');
                $('#product_pictures_slider>li>div>img').eq(currentSlideHtmlObject + 1).addClass('active-slide');
                $('#product_pictures_slider>li>div>img').eq(currentSlideHtmlObject + 1).attr('id', 'img_large');
            },
        });
    }
}

// Change product visu depending on selected color
function changeProductVisu() {

    var path = $(this).data('visu'),
            code = $(this).data('code'),
            container = document.getElementById('img_large'),
            view = document.getElementById('img_large'),
            loader = new Image(),
            err = false;

    loader.onerror = function (evt) {

        if (!err) {

            err = true;

            loader.src = loader.src.replace(code, 'A');
        }
    };

    loader.addEventListener('load', onAlternativeColorLoad.bind(view, loader));

    loader.src = path.replace('medium', 'large');
}

// Change the value from an input number
function changeNumberValue(add) {

    var parent = $(this).closest('.number_input_container').get(0),
            input = $('input[type="hidden"]', parent).get(0),
            display = $('input[type="text"]', parent).get(0),
            value = parseFloat(input.value);

    if (add === 0) {

        input.value = 0;
        display.value = 0;
    } else {

        if (input.getAttribute('min') <= (value + add) && (value + add) <= input.getAttribute('max')) {

            value += add;

            input.value = value;
            display.value = value;
        }
    }
}

function attachShadeClickHandler() {

    shade.one('touchend', closeModalBox.bind(this));
}

// Checks a card number chunk and jump to the next one if needed
function focusCardNumChunk() {

    this.select();
}

function checkCardNumChunk() {

    var type = this.getAttribute('type'),
            vlen = this.value.length,
            mlen = parseFloat(this.getAttribute('maxlength')),
            next = this.nextElementSibling,
            ntyp = (next !== null) ? next.getAttribute('type') : undefined;

    if (vlen >= mlen) {

        if (next !== null && type === ntyp) {

            next.focus();
        } else {

            this.blur();
        }
    }
}

// Save the cardholders info or not
function onSaveInfoChange() {

    var elm = document.getElementById('numero_without_card');

    if (elm && elm.value.match(/X/) && !this.checked) {

        elm.value = '';
    }
}

// Toggles item filters
function toggleFilters() {

    if ($(this).hasClass('active')) {
        $('#shade_filter').css('display', 'none');
        $(this).removeClass('active');
        $(this).closest('.filters_container').children('.content_filter').slideUp(200);
    } else {
        $('#shade_filter').css('display', 'block');
        $(this).addClass('active');
        $(this).closest('.filters_container').children('.content_filter').slideDown(200);
    }
}

// Toggles price sorting
function toggleSortByPrice() {

    // On enleve les animations.
    var monUrl = $(this).attr('id');
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html$');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');

    if (pattern.test(monUrl)) {

        var res = monUrl.match(pattern);
        var t_val = res[1][0];

        if (t_val == '1') {

            this.firstElementChild.innerHTML = translate('price_up_down', '+', '-');
        } else {

            this.firstElementChild.innerHTML = translate('price_up_down', '-', '+');
        }

        monUrl = monUrl.replace(/t[0-9]/, "t" + (t_val == "2" ? "1" : "2"));
    } else if (pattern2.test(monUrl)) {

        monUrl = monUrl.replace(/\.html$/, "/t1/m0.html");
    } else {

        monUrl = monUrl.replace(/\.html$/, "/n72/d0/s/p/c/b/e/t1/m0.html");
    }

    location.href = monUrl;
}

// Fetch a cookie from JS Cookie String
function getCookie(c_name) {

    var c_value = document.cookie;
    var c_start = c_value.indexOf(" " + c_name + "=");

    if (c_start == -1) {

        c_start = c_value.indexOf(c_name + "=");
    }

    if (c_start == -1) {

        c_value = null;
    } else {

        c_start = c_value.indexOf("=", c_start) + 1;

        var c_end = c_value.indexOf(";", c_start);

        if (c_end == -1) {

            c_end = c_value.length;
        }

        c_value = unescape(c_value.substring(c_start, c_end));
    }

    return (c_value);
}

function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, isAssoc, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur, idQte, type_product = false) {

    var form, colors, sizes, quantities, color, size, quantity, designation, cart_btn, array_response = [], output = false, prod_id, modbox;

    if (i != '') {

        if (isAssoc == true) {

            i = 'Assoc_' + i;
        } else {

            i = '_' + i;
        }

        prixU = document.getElementById('prixU' + i).value;
    }

    if ($('[name="itm_quantity' + i + '"]').length) {
        form = $('[name="itm_quantity' + i + '"]').parents('form').get(0);
        colors = form.itm_colour;
        sizes = form.itm_size;
        quantities = form.itm_quantity;
        color = (colors && colors.selectedIndex !== -1) ? colors.value : null;
        size = (sizes && sizes.selectedIndex !== -1) ? sizes.value : null;
        quantity = (quantities && quantities.selectedIndex !== -1) ? quantities.value : null;
    } else {
        form = $('[name="qteProd' + i + '"]').parents('form');
        size = ($('[name="tailleProd' + i + '"]').length) ? $('[name="tailleProd' + i + '"]').val() : '0';
        quantity = $('[name="qteProd' + i + '"]').val();
        quantities = $('[name="qteProd' + i + '"]');

        if ($('[name="couleurUnique' + i + '"]').length)
            color = $('[name="couleurUnique' + i + '"]').val();
        else if ($("#" + idElCouleur).length)
            color = $("#" + idElCouleur).val();
        else
            color = '0';
    }

    // if we have a gift package
    if ($('#isGift').length && $('#isGift').hasClass('checked')) {
        color = idElCouleur;
        size = idElTaille;
    }

    from_range = (i != '' || document.getElementById('lightbox_achat_express'));

    if (color && size && quantity) {

        prod_id = ($('#produit_id_' + idProduit).length && $('#produit_id_' + idProduit).val() != '') ? $('#produit_id_' + idProduit).val() : idProduit;

        $.ajax({
            url: path_web + 'ajax_upd_panier.php',
            type: 'get',
            data: {
                prixu: prixU,
                idprod: prod_id,
                idcouleur: color,
                idtaille: size,
                qte: quantity,
                panierId: paniervirtuelId
            },
            async: false,
            success: function (res) {

                array_response = res.split("//");
                designation = array_response[0];
                cart_btn = document.getElementById('cart_btn');

                if (titreObjet.match('^[0-9]')) {

                    if (designation == 1) {

                        designation += ' lot de';
                    } else {

                        designation += ' lots de';
                    }
                }

                designation += ' ' + titreObjet;

                if ($('input[name="' + idElTaille + '"]').length > 0) {

                    if ($('input[name="' + idElTaille + '"]:checked').length > 0) {

                        designation += ' - Taille ' + $('input[name="' + idElTaille + '"]:checked').attr('data-label');
                    } else if (lastTaille != '') {

                        designation += ' - Taille ' + lastTaille;
                    }
                }

                if ($('input[name="' + idElCouleur + '"]').length > 0) {

                    if ($('input[name="' + idElCouleur + '"]:checked').length > 0) {

                        designation += ' - ' + $('input[name="' + idElCouleur + '"]:checked').attr('data-label');
                    } else if (lastCouleur != '') {

                        designation += ' - ' + lastCouleur;
                    }
                }

                if( array_response[1]  >= 0 ){
                    if (array_response[1] === 0) {
                        if (cart_btn) {
                            cart_btn.classList.remove('count');
                            cart_btn.removeAttr('data-cart');
                        }
                    } else if ( array_response[1] > 0) {
                        modbox = 'item_added';
                        if (cart_btn) {
                            cart_btn.classList.add('count');
                            cart_btn.setAttribute('data-cart', array_response[1]);
                        }

                    }

                    //on modifie la quantite dans le lookbook
                    if ($("#look_detail_wrapper").length) {
                        updateQte_legacy(idProduit, color, size, quantities);
                    }

                    //Emits event when ajax call returns
                    form.trigger('itemAddedToCart'); //item-added-to-cart
                }else{
                    modbox = 'item_error';
                    $('.modbox_title', document.getElementById(modbox)).html(Translator.translate('error_item_add_generic'));
                }

                array_response[0] = designation;
            }
        });

        output = array_response;
        //Ouverture de la popup d'information
        if (type_product !== 'gift') {
            modBoxOpen.call(document.getElementById(modbox));
        }

    } else {

        modbox = 'item_error';

        if (!color) {
            if (colors)
                colors.classList.add('error');
            else
                $("#" + idElCouleur).addClass('error');
            $('.modbox_title', document.getElementById(modbox)).html(Translator.translate('error_select_color'));
        }

        if (!size) {
            if (sizes)
                sizes.classList.add('error');
            else
                $('[name="tailleProd' + i + '"]').addClass('error');
            $('.modbox_title', document.getElementById(modbox)).html(Translator.translate('error_select_size'));
        }

        if (!quantity) {
            if ($('[name="itm_quantity' + i + '"]').length)
                quantities.classList.add('error');
            else
                $('[name="qteProd' + i + '"]').addClass('error');
            $('.modbox_title', document.getElementById(modbox)).html(Translator.translate('error_select_quantity'));
        }

        var offset = 0;
        var timing = $(document).scrollTop() * 0.8;

        if ($('.input_select.error').length > 0 && $('.row_look').length > 0) {

            offset = $('.input_select.error').closest('.row_look').get(0).offsetTop;
            offset = offset - $(".banner_wrapper").get(0).offsetHeight;

            timing = Math.abs($(document).scrollTop() - offset) * 0.8;
        }

        $("html, body").stop(true, false).animate({
            scrollTop : offset
        }, Math.min(timing, window.innerHeight));
    }

    window.setTimeout(function () {

        var loader = $('.button + .loader');

        if (loader.hasClass('loading')) {

            loader.removeClass('loading');
        } else {

            loader.parent().removeClass('loading');
        }
    }, 200);

    return output;
}

function waitingAddToBasket(produit_id, suff, isAssoc, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, idQte, toEval, page_name, path_web, enable_omniture, is_not_produit) {

    var id = '';

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    // On va regarder si la gravure est ouverte
    if ($(".popup_gravure").css('display') == 'block') {

        window.setTimeout(function () {

            var loader = $('.button + .loader');

            if (loader.hasClass('loading')) {

                loader.removeClass('loading');
            } else {

                loader.parent().removeClass('loading');
            }
        }, 200);

        alert('Veuillez valider votre gravure');

        return false;
    }

    window.setTimeout(addToBasket, 1000, produit_id, suff, isAssoc, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, idQte, toEval, page_name, path_web, enable_omniture, is_not_produit);
}

function addToBasket(produit_id, suff, isAssoc, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, idQte, toEval, page_name, path_web, enable_omniture, is_not_produit, reload_page = false) {

    var id = '', prixU = '';

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    prixU = '';
    if (document.getElementById('prixU') !== null) {
        prixU = document.getElementById('prixU').value;
    } else if (document.getElementById('prixU_' + produit_id) !== null) {
        prixU = document.getElementById('prixU_' + produit_id).value;
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, prixU, isAssoc, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, idQte, 'gift');

    // On recharge la page quand on rajoute un emballage cadeau (cf GAP)
    if (reload_page && array_panier !== false) {
        location.reload();
    }

    return false;

    if (array_panier != false) {

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 0) {

            document.getElementById('cart_banner_btn').className.replace(/cart_\d{2,}/, translate('cart_nbr_class', array_panier[1]));
            document.getElementById('cart_banner_btn').setAttribute('data-cart', array_panier[1]);
        } else if (array_panier[1] == 1) {

            if (document.getElementById('cart_banner_btn')) {

                document.getElementById('cart_banner_btn').className.replace(/cart_\d{2,}/, translate('cart_nbr_class', array_panier[1]));
                document.getElementById('cart_banner_btn').setAttribute('data-cart', array_panier[1]);
            }

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajoutÃ© au panier
        } else {

            if (type !== 'err') {

                if (document.getElementById('cart_banner_btn')) {

                    document.getElementById('cart_banner_btn').className.replace(/cart_\d{2,}/, translate('cart_nbr_class', array_panier[1]));
                    document.getElementById('cart_banner_btn').setAttribute('data-cart', array_panier[1]);
                }

                omnitureEvent = 'scAdd'; // il y a dÃ©jÃ  un produit au panier
            }
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        newshowmodal(array_panier[0], array_panier[1], type, path_web, is_not_produit, produit_id);
    } else {

        if ($('.input_select.error').length > 0 && $('.row_look').length > 0) {

            var offset = $('.input_select.error').closest('.row_look').get(0).offsetTop;
            var timing;

            offset = offset - $(".banner_wrapper").get(0).offsetHeight;
            timing = Math.abs($(document).scrollTop() - offset) * 0.8;

            $("html, body").stop(true, false).animate({
                scrollTop : offset
            }, Math.min(timing, window.innerHeight));
        }

        window.setTimeout(function () {

            var loader = $('.button + .loader');

            if (loader.hasClass('loading')) {

                loader.removeClass('loading');
            } else {

                loader.parent().removeClass('loading');
            }
        }, 200);
    }

    var shad = document.getElementById('general_shade');
    eval(toEval);
    if ($('#alertbox').css('display') == 'none')
        $(".lightbox_achat_express").hide();
    addEvent(shad, 'click', closemodal);

    if (document.getElementById('scroll_items')) {

        $("#modbox").appendTo("#content");
        $("#alertbox").appendTo("#content");
        if ($('#alertbox').css('display') == 'none')
            $("#lightbox_achat_express").hide();

    }

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }

        }

    }

    return false;
}

function changeProduitPanier(pI, pProduitId, pPanierVirtuelId) {

    updatePanier(pProduitId, pI, pPanierVirtuelId);
    showQteListePanier('liste_qte_panier' + pI, pProduitId, pI, pPanierVirtuelId);

    showMontanTotalProduit('montantTotalProduit' + pI, pI);
    showMontanTotalPanier('montantPanier');
}

function showTailleListeGamme(elementID, idProduit, parentPath, itm_size) {

    var eltaille = document.getElementById(elementID);

    if (eltaille) {

        $.ajax({
            url: parentPath + 'ajax_liste_taille.php',
            type: 'get',
            data: {idprod: idProduit, itm_size: itm_size},
            async: false,
            success: function (res) {

                eltaille.innerHTML = res;
            }
        });
    }
}


$.fn.lastProduct = function () {
    var $list = $(this);
    function calculLast() {
        if ($list.length > 1) {
            var firstPos = $list.eq(0).offset();
            var last = 0;
            $list.each(function (i) {
                var $elem = $(this);
                var $posElem = $elem.offset();
                if ($posElem.top == firstPos.top) {
                    last++;
                } else {
                    return false;
                }
            });
            $list.each(function (i) {
                if ($(this).is('.last')) {
                    $(this).removeClass('last');
                }
                $(this).removeClass('not-last');
                if (i % last == (last - 1)) {
                    $(this).addClass('last');
                } else {
                    $(this).addClass('not-last');
                }
            })
        }
    }
    $(function () {
        calculLast()
    });
    $(window).on('resize', function () {
        calculLast();
    });

    $(document).ajaxSuccess(function (e) {
        calculLast();
    });
}

$(function () {

    var $list_views = $('#list_views'),
            $view_elems = $('.view_elem', $list_views),
            currentView = $view_elems.filter('.on').data('v');

    window.assignViewReload = function (currentView) {

        $view_elems.not('[data-v="' + currentView + '"]').data('reload', true);
    };

    $list_views.on('click', '.view_elem:not(.on)', function () {

        var $obj = $(this);
        var objDataV = $obj.data('v');
        var valCookie = readCookie('slider_display');

        if (valCookie != 'slider' && valCookie != 'mozaic') {

            eraseCookie('slider_display');
        }

        var $mozaic = $('#mozaic');
        var $slider = $('#slider');
        var $screen_loader = $('#screen_loader');
        var showSpeed = 800;

        $view_elems.removeClass('on');
        $obj.addClass('on');

        if (objDataV == 'slider') {

            $slider.parent().css({overflow: 'hidden'});
            $mozaic.css({opacity: 1, display: 'block'}).animate({opacity: 0}, showSpeed, function () {

                $mozaic.css({opacity: '', display: 'none'});
                $slider.css({opacity: 0, display: 'block'}).animate({opacity: 1}, showSpeed, function () {

                    loadSlider(showSpeed);
                    $slider.css({opacity: ''});
                    valCookie = objDataV;
                });
            });
        } else if (objDataV == 'mozaic') {

            $slider.css({opacity: 1, display: 'block'}).animate({opacity: 0}, showSpeed, function () {

                $slider.css({opacity: '', display: 'none'});
                $mozaic.parent().css({overflow: ''});
                $mozaic.css({opacity: 0, display: 'block'}).animate({opacity: 1}, showSpeed, function () {

                    $mozaic.css({opacity: ''});
                    valCookie = objDataV;
                });
            });
        }

        createCookie('slider_display', objDataV, 7);
        assignViewReload(currentView);
    });
});

function showerrormessage() {

    modBoxOpen.call(document.getElementById('modboxpromo'));
}

function addEventPromo(elem, event, name_function) {

    if (document.all) {

        elem.attachEvent("on" + event, name_function);
    } else {

        elem.addEventListener(event, name_function, false);
    }
}

function closeerrormessage() {

    var modbox = document.getElementById('modboxpromo');
    var topconnexion = document.getElementById('topMenuConnexionButtonInside');
    var shad = document.getElementById('shad');

    modbox.style.marginLeft = '';
    shad.style.display = "none";
    modbox.style.display = "none";

    if (topconnexion) {

        topconnexion.style.zIndex = 1501;
    }

    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = "";

    delEventPromo(shad, 'click', closeerrormessage);
}

function motifChange(motif, id_transporteur) {

    var motifValue = motif.value,
            label = 'btnPrintPdf';

    if (typeof (id_transporteur) != 'undefined') {

        label += '_' + id_transporteur;
    }

    var divBtnPrintPdf = $("#" + label);

    if (motifValue == '0') {

        divBtnPrintPdf.css('display', 'none');
    } else {

        divBtnPrintPdf.css('display', 'block');
    }
}

function motifChangeDiv(motif, id_transporteur) {

    var motifValue = motif.value,
        label = 'divBtnPrintPdf';

    if (typeof (id_transporteur) != 'undefined') {

        label += '_' + id_transporteur;
    }

    var divBtnPrintPdf = $("#" + label);

    if (motifValue == '0') {

        divBtnPrintPdf.css('display', 'none');
    } else {

        divBtnPrintPdf.css('display', 'block');
    }
}

function extractData(data) {

    var output = {},
            inc = 0;

    for (inc = 0; inc < data.length; inc += 1) {

        output[data[inc]['name']] = data[inc]['value'];
    }
}

function sendWishList(path_relative_root) {

    if ($('#sendwishlistbox').length == 0) {

        $.ajax({
            url: path_relative_root + 'ajax_send_wishlist.php',
            success: function (response) {

                if ($('#sendwishlistbox').length == 0) {

                    $('body').append(response);
                }
            },
            complete: function () {

                openPopup('sendwishlistbox');

                $('.choice_elem', '.choose_theme').click(function (event) {

                    var $obj = $(this);
                    var dValue = $obj.attr('data-value');

                    if (!$obj.hasClass('select')) {

                        $('.choice_elem', '.choose_theme').removeClass('select');

                        $obj.addClass('select');

                        $('#theme').attr('value', dValue);
                    }
                });
            }
        });
    } else {

        openPopup('sendwishlistbox');
    }
}

function sendWishListForm(evt) {

    var loadze, form, theme, to_mails, to_message, your_name, your_mail, errbox,
            output_txt;

    evt.preventDefault();

    loader = $(this.nextElementSibling);

    loader.fadeIn(200, function () {

        form = $(this).closest('form').get(0);

        to_mails = form.sendWishListToMails;
        to_message = form.sendWishListToMessage;
        your_name = form.sendWishListYourName;
        your_mail = form.sendWishListYourMail;

        errbox = document.getElementById('item_error');
        token = document.getElementById('token_security_sendwishlist').value;
        captcha = document.getElementById('g-recaptcha-response').value;

        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist_form'),
            type: 'get',
            data: {
                theme: theme, to_mails: to_mails.value,
                to_message: to_message.value, your_name: your_name.value,
                your_mail: your_mail.value,
                token_security : token,
                'g-recaptcha-response': captcha
            },
            success: function (res) {

                $('.inputErr', form).removeClass('inputErr');

                if (res.indexOf('ok') === 0) {

                    var s_adresse = res.substr(3);
                    var sing = (res.split(',').length > 2) ? 'sendfriend_ok_plur_mobile' : 'sendfriend_ok_sing_mobile';

                    output_txt = Translator.translate(sing, s_adresse);

                    closeModalBox.call(document.getElementById('wishlist_share'));
                } else {

                    if (!isNaN(Number(res))) {

                        switch (Number(res)) {

                            case 1:
                            case 2:
                                $(to_mails).addClass('inputErr');
                                output_txt = Translator.translate('sendfriend_error_mail_friends_invalid');
                                break;

                            case 3:
                                $(to_message).addClass('inputErr');
                                output_txt = Translator.translate('sendfriend_error_no_message');
                                break;

                            case 4:
                                $(your_name).addClass('inputErr');
                                output_txt = Translator.translate('sendfriend_error_no_name');
                                break;

                            case 5:
                            case 6:
                                $(your_mail).addClass('inputErr');

                            case 5:
                                output_txt = Translator.translate('sendfriend_error_no_my_mail');
                                break;

                            case 6:
                                output_txt = Translator.translate('sendfriend_error_my_mail_invalid');
                                break;
                        }
                    } else if(res == 'token_error'){
                        output_txt = Translator.translate('error_token_security');
                    } else if (Number(res.split(',')[0]) === 7) {

                        var s_adresse = res.substr(3);
                        var sing = (res.split(',').length > 2) ? 'sendfriend_no_ok_plur_mobile' : 'sendfriend_no_ok_sing_mobile';

                        output_txt = translate(sing, s_adresse);
                    }
                }

                $('.modbox_title', errbox).html('<span>' + output_txt + '</span>');

                modBoxOpen.call(errbox);

                loader.stop(true, true).fadeOut(200);

                $("html,body").css('position','initial');
            }
        });
    });
}

function affinity_check_all_form(form) {

    var bErreur = false,
            txt = '',
            elem_err = '';

    $("#" + form.id + " input,select,radio").each(function () {

        elem_err = this.id + "Err";

        if (this.id == "privilege_sexe_h") {

            if ($('#privilege_sexe_h')[0].checked == false && $('#privilege_sexe_f')[0].checked == false) {

                $('.label_sexe').addClass('labelErr');

                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'login_erreur_70\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;

            } else {

                $('.label_sexe').removeClass('labelErr');

                $("#" + elem_err).parent().hide();
            }

        }

        if (this.id == "privilegeDatenaisA") {

            if ($('#privilegeDatenaisJ').val() == 0 || $('#privilegeDatenaisM').val() == 0 || this.value == 0) {

                $('#privilegeDatenaisJ').parent().addClass('inputErr');
                $('#privilegeDatenaisM').parent().addClass('inputErr');
                $('#privilegeDatenaisA').parent().addClass('inputErr');

                $('#privilegeDatenaisALabel').addClass('labelErr');

                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'login_erreur_24\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {

                $('#privilegeDatenaisJ').parent().removeClass('inputErr');
                $('#privilegeDatenaisM').parent().removeClass('inputErr');
                $('#privilegeDatenaisA').parent().removeClass('inputErr');

                $('#privilegeDatenaisALabel').removeClass('labelErr');

                $("#" + elem_err).parent().hide();
            }

        }
        if (this.id == "pays") {

            if ($('#pays').val() == '') {

                $('#pays').parent().addClass('inputErr');

                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'login_erreur_54\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {

                $('#pays').parent().removeClass('inputErr');

                $("#" + elem_err).parent().hide();
            }

        } else if (this.id == "departement_naissance") {

            if (verif_num(this.id, this.id, 2) === false) {
                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'numero_departement_incorrect\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {
                $("#" + elem_err).parent().hide();
            }

        } else if (this.id == "adresse_postale") {

            if (verif_alphanumadresse(this.id, this.id) === false) {
                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'login_erreur_5\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {
                $("#" + elem_err).parent().hide();
            }

        } else if (this.id == "code_postal") {

            if (!this.value.match(/((0[1-9])|([1-8][0-9])|(9[0-8]))[0-9]{3}/)) {

                $('#code_postal').addClass('inputErr');

                $('#code_postalLabel').addClass('labelErr');

                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'login_erreur_9\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {

                $('#code_postal').removeClass('inputErr');

                $('#code_postalLabel').removeClass('labelErr');

                $("#" + elem_err).parent().hide();
            }

        } else if (this.id == "ville") {

            if (verif_alpha(this.id, this.id) === false) {
                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'login_erreur_10\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {
                $("#" + elem_err).parent().hide();
            }

        } else if (this.id == "nbr_foyer") {

            if (verif_num(this.id, this.id) === false) {

                $('#nbr_foyer').parent().addClass('inputErr');

                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'erreur_nbr_foyer\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {

                $('#nbr_foyer').parent().removeClass('inputErr');

                $("#" + elem_err).parent().hide();
            }

        } else if (this.id == "privilege_email") {

            var objEMail = document.getElementById(this.id),
                    lblEMail = document.getElementById(this.id + 'Label');

            if (objEMail.value == '') {

                $(objEMail).addClass('inputErr');
                $(lblEMail).addClass('labelErr');

                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'login_erreur_17\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else if (verif_mail(this.id, this.id) === false) {

                $(objEMail).addClass('inputErr');
                $(lblEMail).addClass('labelErr');

                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'erreur_mail_incorrect\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {

                $(objEMail).removeClass('inputErr');
                $(lblEMail).removeClass('labelErr');

                $("#" + elem_err).parent().hide();
            }

        } else if (this.id == "mot_de_passe") {

            if (this.value.length < 3) {

                $(this).addClass('inputErr');

                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'co_rollover_erreurmdp\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {
                $("#" + elem_err).parent().hide();
            }

        } else if (this.id == "privilege_mobile") {

            var tel_numb = this.value.replace(/[\D]+/, '');

            if (verif_portable(this.id, this.id) === false) {
                $("#" + elem_err).attr('onmouseover', 'showTooltip(this, \'erreur_mobile_incorrect\')');
                $("#" + elem_err).attr('onmouseout', 'hideTooltip()');
                $("#" + elem_err).parent().show();
                bErreur = true;
            } else {
                $("#" + elem_err).parent().hide();
            }
        }
    });

    return !bErreur;

}

function create_card_check_all_form(form) {
    var bErreur = false,
        txt = "",
        elem_err = "";

    $("#" + form.id + " input,select,radio").each(function() {
        elem_err = this.id + "Err";

        if (this.id == "privilege_sexe_h") {
            if (
                $("#privilege_sexe_h")[0].checked == false &&
                $("#privilege_sexe_f")[0].checked == false
            ) {
                $(".label_sexe").addClass("labelErr");

                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'login_erreur_70')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                bErreur = true;
            } else {
                $(".label_sexe").removeClass("labelErr");

                $("#" + elem_err)
                    .parent()
                    .hide();
            }
        }

        if (this.id == "privilege_nom") {
            var objNom = document.getElementById(this.id),
                lblNom = document.getElementById(this.id + "Label");

            if (objNom.value == "") {
                $(objNom).addClass("inputErr");
                $(lblNom).addClass("labelErr");

                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'login_erreur_1')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                bErreur = true;
            } else {
                $(objNom).removeClass("inputErr");
                $(lblNom).removeClass("labelErr");

                $("#" + elem_err)
                    .parent()
                    .hide();
            }
        }

        if (this.id == "privilege_prenom") {
            var objPrenom = document.getElementById(this.id),
                lblPrenom = document.getElementById(this.id + "Label");

            if (objPrenom.value == "") {
                $(objPrenom).addClass("inputErr");
                $(lblPrenom).addClass("labelErr");

                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'login_erreur_2')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                bErreur = true;
            } else {
                $(objPrenom).removeClass("inputErr");
                $(lblPrenom).removeClass("labelErr");

                $("#" + elem_err)
                    .parent()
                    .hide();
            }
        }

        if (this.id == "privilegeDatenaisA") {
            if (
                $("#privilegeDatenaisJ").val() == 0 ||
                $("#privilegeDatenaisM").val() == 0 ||
                this.value == 0
            ) {
                $("#privilegeDatenaisJ")
                    .parent()
                    .addClass("inputErr");
                $("#privilegeDatenaisM")
                    .parent()
                    .addClass("inputErr");
                $("#privilegeDatenaisA")
                    .parent()
                    .addClass("inputErr");

                $("#privilegeDatenaisALabel").addClass("labelErr");

                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'login_erreur_24')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                bErreur = true;
            } else {
                $("#privilegeDatenaisJ")
                    .parent()
                    .removeClass("inputErr");
                $("#privilegeDatenaisM")
                    .parent()
                    .removeClass("inputErr");
                $("#privilegeDatenaisA")
                    .parent()
                    .removeClass("inputErr");

                $("#privilegeDatenaisALabel").removeClass("labelErr");

                $("#" + elem_err)
                    .parent()
                    .hide();
            }
        }
        if (this.id == "code_postal") {
            if (!this.value.match(/((0[1-9])|([1-8][0-9])|(9[0-8]))[0-9]{3}/)) {
                $("#code_postal").addClass("inputErr");

                $("#code_postalLabel").addClass("labelErr");

                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'login_erreur_9')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                bErreur = true;
            } else {
                $("#code_postal").removeClass("inputErr");

                $("#code_postalLabel").removeClass("labelErr");

                $("#" + elem_err)
                    .parent()
                    .hide();
            }
        }

        if (this.id == "privilege_email") {
            var objEMail = document.getElementById(this.id),
                lblEMail = document.getElementById(this.id + "Label");

            if (objEMail.value == "") {
                $(objEMail).addClass("inputErr");
                $(lblEMail).addClass("labelErr");

                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'login_erreur_17')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                bErreur = true;
            } else if (verif_mail(this.id, this.id) === false) {
                $(objEMail).addClass("inputErr");
                $(lblEMail).addClass("labelErr");

                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'erreur_mail_incorrect')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                bErreur = true;
            } else {
                $(objEMail).removeClass("inputErr");
                $(lblEMail).removeClass("labelErr");

                $("#" + elem_err)
                    .parent()
                    .hide();
            }
        }

        if (this.id == "privilege_mobile") {
            var tel_numb = this.value.replace(/[\D]+/, "");

            if (this.value && verif_portable(this.id, this.id) === false) {
                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'erreur_mobile_incorrect')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                $('#privilege_mobile').addClass("inputErr");
                bErreur = true;
            } else if ( (($('#wrapper_optin_sms').length && $('#optin_sms_y').is(':checked')) || $('#mobile_mandatory').length) && !this.value) {
                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'erreur_mobile')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                $('#privilege_mobile').addClass("inputErr");
                bErreur = true;
            } else {
                $("#" + elem_err)
                    .parent()
                    .hide();
                $('#privilege_mobile').removeClass("inputErr");
            }
        }

        if (this.id == "optin_affinity_y") {
            if (
                !$('input[type=radio][name=optin_affinity]:checked').length
            ) {
                $("#wrapper_optin_affinity").addClass("labelErr");

                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'please_select_optin')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                bErreur = true;
            } else {
                $("#wrapper_optin_affinity").removeClass("labelErr");

                $("#" + elem_err)
                    .parent()
                    .hide();
            }
        }

        if (this.id == "optin_sms_y") {
            if (
                !$('input[type=radio][name=optin_sms]:checked').length
            ) {
                $("#wrapper_optin_sms").addClass("labelErr");

                $("#" + elem_err).attr(
                    "onmouseover",
                    "showTooltip(this, 'please_select_optin')"
                );
                $("#" + elem_err).attr("onmouseout", "hideTooltip()");
                $("#" + elem_err)
                    .parent()
                    .show();
                bErreur = true;
            } else {
                $("#wrapper_optin_sms").removeClass("labelErr");

                $("#" + elem_err)
                    .parent()
                    .hide();
            }
        }

    });
    return !bErreur;
}

// Boutique Cadeau
function addGift() {

    var p_id = $('input[name="produit_id"]').val(),
            c_id = $('input[name="itm_colour"]:checked').val(),
            s_id = ($('input[name="itm_size"]').length) ? $('input[name="itm_size"]:checked').val() : 0,
            nmbr = $('#itm_quantity').val(),
            data = {
                produit_id: p_id,
                couleur_id: c_id,
                taille_id: s_id,
                qte: nmbr
            },
            $btn = $('input', '#add_basket'),
            $ldr = $('#loader'),
            $err = $('#item_error'),
            resp = false;

    if (c_id !== '' && s_id !== '' && nmbr !== '') {

        $.ajax({
            url: 'ajax_checkAddGift.php',
            type: 'post',
            async: false,
            data: data,
            beforeSend: function () {

                $btn.hide();
                $ldr.show();
            },
            success: function (res) {

                try {

                    res = JSON.parse(res);

                    if (res.success === 'ok') {

                        resp = true;
                    } else {

                        $('.modbox_title', $err).html(res.error.msg);

                        modBoxOpen.call($err.get(0));

                        $ldr.hide();
                        $btn.show();
                    }
                } catch (err) {

                    $ldr.hide();
                    $btn.show();

                    console.error(err.message);
                }
            }
        });
    } else {

        if (c_id === '') {

            t_er = ajax_file('ajax_sprintf.php?arg1=please_select_color');
        } else if (s_id === '') {

            t_er = ajax_file('ajax_sprintf.php?arg1=please_select_size');
        } else if (nmbr === '') {

            t_er = ajax_file('ajax_sprintf.php?arg1=please_select_quantity');
        }

        $('.modbox_title', $err).html(t_er);

        modBoxOpen.call($err.get(0));
    }

    return resp;
}

function closeZoom() {

    var elname = 'zoomBox';
    var modbox = document.getElementById(elname);
    var shad = document.getElementById('shad');
    var shad_zoom = document.getElementById('shad_zoom');
    var topconnexion = document.getElementById('topMenuConnexionButtonInside');
    modbox.style.display = "";
    shad_zoom.style.display = "";

    delEvent(shad, 'click', closeZoom);
    delEvent(shad_zoom, 'click', closeZoom);
    delEvent(modbox, 'click', closeZoom);
    topconnexion.style.zIndex = 1501;

    closemodal();

}

function changeVisuZoom(field, path) {

    var elem = (document.getElementById('nyroModalImg')) ? 'nyroModalImg' : 'img_tmp';

    var w_tmp = $('#' + elem).width();
    var h_tmp = $('#' + elem).height();

    document.getElementById(field).innerHTML = "<div id='loader_tati' style='z-index:1;position:relative;top:400px;text-align:center;font-family:NeueKursivschriftLTPro-StehendS;font-size:16px;color:#E9568E;'><img src='" + path_relative_root + "img/loader.gif' /><p><strong>Chargement...</strong></p></div>";
    document.getElementById(field).innerHTML += "<div style='position:relative;z-index:2;top:-41px;'><img id='img_tmp' src=" + path + "></div>";
    // $("#"+field).attr('src',path)

    $('#img_tmp').load(function () {
        $('#loader_tati').remove();
        $(this).parent().css('top', '0');
        $(this).width(w_tmp);
        $(this).height(h_tmp);
    });

}

// JS Translator functions
function parseArgs(args) {

    var obj = {},
            inc = 0,
            len = args.length;

    for (inc; inc < len; inc += 1) {

        obj['arg' + inc] = args[inc];
    }

    return obj;
}

function translate() {
    var o_data = parseArgs(arguments),
            s_resp = arguments[0];

    $.ajax({
        url: path_relative_root + 'ajax_sprintf.php',
        type: 'get',
        data: o_data,
        async: false,
        success: function (res) {

            s_resp = res
        }
    });

    return s_resp;
}

function changeajaxfilterRayon(form, typeFiltre, id, resetObj) {
    var value = getInputVal.bind(form), idTmp = id, id = value('type_nav', 'tous'),
            type = value('type_tri'), idObj,
            btn = this, idObjTmp;

    resetObj = resetObj || false;

    if($('#select_sscat_'+idTmp).hasClass('redirect_sscat') && $('#select_sscat_'+idTmp).is(':checked')) {
        var url_redirect = path_relative_root + $('#select_sscat_'+idTmp).attr("data-href");
        setTimeout(function(){ location.href = url_redirect; }, 1000);
    }

    // Check obj type
    if (form.sscat) {

        // Attention ce n'est pas des sscat mais des bien objets (views_mobile_new/wrapper_filters.php)
        idObjTmp = value('sscat');

        if (idObjTmp && idObjTmp.length > 0) {
            type = 'obj';
            $('#object_id').val(idObjTmp);
        } else {
            type = 'subcat';
            $('#object_id').val('');
        }
    }

    $('#type_tri').val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {
        btn.nextElementSibling.style.display = 'block';
    }

// on decoche tout ppour faire une action radio
    if (resetObj == true) {
        resetCheckBox('eclats');
        resetCheckBox('brand');
        resetCheckBox('taille');
        resetCheckBox('couleur');
        resetCheckBox('promo');
        resetCheckBox('store');
        resetCheckBox('charact');
        resetCheckBox('theme');
        resetCheckBox('in_stock');
    }

    generateNewBlocFilter.call(form, type, idObj, 0, value('nb_prod'), btn, typeFiltre, id);

    // Prevent default action
    return false;
}
//
function resetCheckBox(name){

    // If the filter product in stock exist we reset the elem
    if ($("input[name=\"" + name + "\"]").length) {
        $("input[name=\"" + name + "\"]:checked").each(function () {
            $(this).attr('checked', false);
        });
    }
}

function changeajaxfilter(typeFiltre, id) {

    var array_size = new Array;
    var array_couleur = new Array;
    var array_categ = new Array;
    var array_marque = new Array;
    var array_store = new Array;
    var array_charact = new Array;



    if (typeFiltre != "reset") {
        array_size = generateListCritere('taille');
        array_couleur = generateListCritere('couleur');
        array_categ = generateListCritere('idcateg');
        array_marque = generateListCritere('promo');
        array_store = generateListCritere('store');
        array_charact = generateListCritere('charact');

    }

    var data = {
        keyword: $('#query_keyword').val(),
        id_page: getCurrentIdRayon(),
        nb_prod: $('#nb_prod').val(),
        type_filtre: typeFiltre,
        categorie_id: array_categ,
        color_id: array_couleur,
        size_id: array_size,
        brand: array_marque,
        store: array_store,
        charact: array_charact,

    };

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_change_filtre.php',
        data: data,
        success: function (response) {
            var resultats = jQuery.parseJSON(response);

            var typeFilter = resultats.filter;
            var array_size = resultats.size;
            var array_colors = resultats.color;
            var array_brand = resultats.brand;
            var array_store = resultats.store;
            var array_categ = resultats.categ;
            var array_charact = resultats.charact;
            var nb_products = resultats.nb_products;
            if (array_size.length > 0 || typeFilter != 'taille')
                generateListFilter('taille', array_size);
            if (array_colors.length > 0 || typeFilter != 'couleur')
                generateListFilter('couleur', array_colors);

            if (array_brand.length > 0 || typeFilter != 'brand') {
                generateListFilter('promo', array_brand);
            }

            if (array_store.length > 0 || typeFilter != 'store') {
                generateListFilter('store', array_store);
            }

            if (array_charact.length > 0 || typeFilter != 'charact')
                generateListFilter('charact', array_charact);

            if (array_categ.length > 0 || typeFilter != 'categ')
                generateListFilter('idcateg', array_categ);


            $('#nbr_items').html(nb_products);

        }
    });

}

function generateListFilter(type, array_params, is_produit)
{
    var conteneur = '';
    var changeajax = 'changeajaxfilter';
    var form = '';

    is_produit = is_produit || false;

    if (is_produit == true) {
        changeajax = 'changeajaxfilterRayon';
        form = 'this.form,';
    }

    switch (type) {
        case 'promo' :
            conteneur = 'brand';
            break;

        case 'idcateg':
            conteneur = 'categ';
            break;

        default:
            conteneur = type;
            break;
    }
    var array_checked = [];

    $("input[name=" + type + "]:checked").each(function ()
    {
        array_checked.push($(this).val());
    });

    $('li#wrapper_' + conteneur + '_filtre ul.filter_options').empty();

    var is_privilege = '';

    for (var i = 0; i < array_params.length; i++) {

        if (type == "eclat") {
            if (array_params[i][2] != '0') {
                is_privilege = "is_privilege";
            }
        }

        var liste_html = '';

        // l'array_params étant différent pour le type store (tableau associatif au lieu de tableau numéroté) , on passe par deux variables pour injecter le html dans liste_html
        if (type == 'store') {
            var id = array_params[i]['magasin_id'];
            var nom = array_params[i]['magasin_ville'] + ' (' + array_params[i]['magasin_nom'] + ')';
        } else {
            var id = array_params[i][0];
            var nom = array_params[i][1];
        }
        liste_html += '<li class ="option '+ is_privilege + '">';
        liste_html += '<input data-name="' + array_params[i]['slug'] + '" id="select_' + type + '_' + id + '" type="checkbox" name="' + type + '" value="' + id + '" onchange = "' + changeajax + '( ' + form + ' \'' + conteneur + '\', ' + id + ' )">';
        liste_html += '<label for="select_' + type + '_' + id + '">' + ((type=='couleur')? nom.charAt(0).toUpperCase() + nom.slice(1).toLowerCase():nom) + '</label>';

        if (type=='couleur') {
            var style_color = (array_params[i][3] != '' ) ? 'background-color:' + array_params[i][3] : 'background: url(' + path_relative_root + 'img/picto/color_' + id + '.jpg) repeat scroll center center transparent';
            liste_html += '<span style="' + style_color +';"></span>';
        }

        liste_html += '</li>';
        $('li#wrapper_' + conteneur + '_filtre ul.filter_options').append(liste_html);

        if ($.inArray(id, array_checked) !== -1) {
            $('#select_' + type + '_' + id).prop("checked", true);
        }
    }

}

function generateNewBlocFilter(type, idObj, page, nb_product, btn, typeFiltre, id) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
            output = {},
            tmpoutput = {},
            is_from_page = false,
            key;

    var thisform = form;

    // Get all filters
    form = Array.prototype.slice.call(form.elements, 0);
    var keys = new Array();

    if (use_new_filter_url) {
        output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    } else {
        // Build an object to store active filters
        form.forEach(function (elm) {
            if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                    if (output[elm.name] === undefined) {
                        output[elm.name] = [];
                    }
                    output[elm.name].push(parseFloat(elm.value));
                }
                keys[elm.name] = 1;
            }
        });
        // Convert filters arrays to strings
        for (key in output) {

            if (output.hasOwnProperty(key)) {

                if (output[key].constructor === Array) {

                    output[key] = output[key].join('~');
                }
            }
        }
        if( $(".filter_date").length > 0 ) {

            output.multi_object = 'true';
            output.id_category_super = id_category_super;

            if( typeof(output['date']) == 'undefined' ) {
                output.date = 4;
            }
            else {
                if (output['date'] == 4)
                    output['date'] = tmp_date;
            }
        }
        else {
            if (output['date'] == 4) {
                output['date'] = tmp_date;
            }
        }

        if (nb_product !== undefined) {

            this.nb_prod.value = nb_product;
        } else {

            nb_product = this.nb_prod.value;
        }

        // Add misc values to output object
        output.page = this.page.value;
        output.type = type;
        output.parentPath = path_relative_root;
        output.idObj = idObj;
        output.nb_prod = nb_product;
        output.is_page = is_from_page;
        output.nb_tot_prod = this.totalElems.value;
        output.typeFiltre = typeFiltre;

    }

    /** LEGACY **/

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_change_filtre_produit.php',
        type: 'get',
        data: output,
        success: function (response) {

            var resultats = jQuery.parseJSON(response);

            var typeFilter = resultats.filter;
            var array_size = resultats.size;
            var array_colors = resultats.color;
            var array_brand = resultats.brand;
            var array_eclat = resultats.eclat;
            var array_store = resultats.store;

            if (typeFilter == 'sscat' && $('.container_products_lookbook').length == 0 ) {
                if (array_colors.length == 0 || array_size.length == 0) {
                    changeajaxfilterRayon(thisform, 'all', id, true);
                    return true;
                }
            }

            if (typeFilter != 'taille') {
                generateListFilter('taille', array_size, true);
            }

            if (typeFilter != 'couleur') {
                generateListFilter('couleur', array_colors, true);
            }

            if (typeFilter != 'brand') {
                generateListFilter('promo', array_brand, true);
            }

            if (typeFilter != 'eclat') {
                generateListFilter('eclat', array_eclat, true);
            }

            if (typeFilter != 'store') {
                generateListFilter('store', array_store, true);
            }

            /* Nombre de produits */
            // Get all filters
            form = Array.prototype.slice.call(thisform.elements, 0);
            // Build an object to store active filters
            form.forEach(function (elm) {
                if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                    if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                        if (tmpoutput[elm.name] === undefined) {
                            tmpoutput[elm.name] = [];
                        }

                        if (elm.name == 'charact') {
                            tmpoutput[elm.name].push(elm.value);
                        } else {
                            tmpoutput[elm.name].push(parseFloat(elm.value));
                        }
                    }
                }
            });

            // Convert filters arrays to strings
            for (key in keys) {
                if (tmpoutput.hasOwnProperty(key)) {
                    output[key] = tmpoutput[key].join('~');
                } else {
                    delete output[key];
                }
            }

            if( $(".filter_date").length > 0 ) {

                output.multi_object = 'true';
                output.id_category_super = id_category_super;

                if( typeof(output['date']) == 'undefined' ) {
                    output.date = 4;
                }
                else {
                    if (output['date'] == 4)
                        output['date'] = tmp_date;
                }
            }
            else {
                if (output['date'] == 4) {
                    output['date'] = tmp_date;
                }
            }

            bloc_prod_xhr = $.ajax({
                url: path_relative_root + 'ajax_reload_products.php',
                type: 'get',
                data: output,
                success: function (res) {
                    var new_elm = document.createElement('div'),
                            old_elm = document.getElementById('scroll_items'),
                            content, itm_length;

                    res = res.split('><><');

                    content = res[0].trim();

                    if (res[1] !== '') {
                        itm_length = parseFloat(res[1]);

                        //Permet d'actualiser lors de l'application d'un filtre, le nombre de produits total restants après application
                        //Info acutalisée en haut du filters_menu - potentiellement en display:none;
                        document.getElementById('nbr_items').innerHTML = itm_length;
                        document.getElementById('totalElems').value = itm_length;

                        if (window.lazyload) {

                            lazyload.init();
                        }
                    }
                    $('#is_loading').val('0');
                }
            });
        }

    });

}
/**
 * Permet de mettre Ã  jour la liste des produits cadeaux
 * @author AurÃ©lien Renault
 * @since  2016-05-19
 * @param  form : le formulaire qui contient tous les elements pour le filtre
 * @param  parentPath : l'url pour l'affichage du resultat
 */
function generateNewBlocGift(form, parentPath, btn) {

    var parametres = 'parentPath=' + parentPath;

    // Contruction des parametres
    $(form).find('input, select').each(function () {
        if ($(this).data('name') != '')
            parametres += '&' + $(this).data('name') + '=' + $(this).val();
    });


    $.ajax({
        url: parentPath + 'ajax_reload_gift.php',
        type: 'get',
        data: parametres,
        success: function (response) {

            btn.next('.pink_loader').hide();
            // On signale qu'on ne charge plus les donnÃ©es
            btn.removeClass('cache');

            var tab_response = response.split('><><');
            // On regarde si il y a du contenu
            if (tab_response[0] != '') {

                nb_articles = tab_response[1];

                var bloc_photo = tab_response[0];

                if (document.getElementById('list_item') && bloc_photo != null)
                    document.getElementById('list_item').innerHTML = bloc_photo;

                if (document.getElementById('title_nb_articles') && nb_articles != null)
                    document.getElementById('title_nb_articles').innerHTML = nb_articles;

                var nav = tab_response[2];
                var nav_bas = tab_response[3];

                var nb_prod = tab_response[4];
                if (document.getElementById('nb_prod_numeric'))
                    document.getElementById('nb_prod_numeric').innerHTML = nb_prod;
            } else {
                document.getElementById('list_item').innerHTML = ajax_file(parentPath + 'ajax_sprintf.php?arg1=no_response_search');
                if (document.getElementById('title_nb_articles'))
                    document.getElementById('title_nb_articles').innerHTML = 0;
            }

            // Initialisa le lazyload (pour charger les image sans scroller)
            if (window.lazyload) {

                lazyload.init();
            }

            // Disparition du formulaire des filtres et du shade
            toggleFilters.call($('.filter_toggle'));
        }
    });
}

function generateListCritere(type) {

    var array_filters = new Array;
    $("input[name=" + type + "]:checked").each(function ()
    {
        array_filters.push($(this).val());
    });

    return array_filters;
}

function generateSimpleCritere(type) {

    var critere = 0;
    if ($("input[name=" + type + "]").length > 0) {
        critere = $("input[name=" + type + "]:checked").val()
    }
    return critere;
}

function addToWishlistShoppingcart(){

    $.post(path_relative_root + "ajax_cart_to_wishlist.php", function (resp) {
        $(".btn_top_wishlist .gris").html('(x' + resp.result.wishlist_size + ')');
        $("#addToWishlistShoppingcart").removeClass("addToWishlistShoppingcart").addClass("existsToWishlistShoppingcart");
        $("#addToWishlistShoppingcart").html(resp.result.button_label);
        $("#addToWishlistShoppingcart").attr("onclick", "").attr("href", resp.result.button_link);
        location.reload();
    });
}


//change les parametres du produit lorsque la couleur ou le stock est selectionne
function onAttrSelect_legacy(idProduit) {

    var form = $('#content_product'),
            sizes = $('[name="tailleProd_' + idProduit + '"]'),
            quantities = $('[name="qteProd_' + idProduit + '"]'),
            color, color_id, size, color_option, produit_id,
            produit_lot_nb = 0;
    var colors = ($('[name="couleurUnique_' + idProduit + '"]').length) ? $('[name="couleurUnique_' + idProduit + '"]') : $('[name="couleurProd_' + idProduit + '"]');

    if (this.value) {
        this.classList.remove('error');
    }

    color = (colors) ? colors.val() : 'none';

    if (color !== 'none') {

        color_id = colors.attr('id');
		if( this.classList.contains('color') ) updateColor_legacy.call($('#' + color_id + ' option:eq(' + colors.prop('selectedIndex') + ')'), parseFloat(idProduit));

        if (this !== window && this.classList.contains('color')) {
            setAvailableSizes_legacy.call(this, parseFloat(idProduit));
        }
    }

    if (color == '') {

        if ($('input[name="firstColorStock_' + idProduit + '"]') !== undefined) {
            var color = $('input[name="firstColorStock' + idProduit + '"]').val();
            if (color) {
                $('#couleurProd_' + idProduit + ' option[value="' + color + '"]').prop('selected', true);
            }

        }

    }

    size = (sizes && sizes.val() !== '0') ? sizes.val() : 'none';

    if ($('#lot_nb_prod_' + idProduit).length) {
        if (size === 0) {
            size = 'none';
        }
    }

    //on recherche le nouvel id du produit, au cas ou il a change lors du changement de couleur
    produit_id = $('#produit_id_' + idProduit).val();

    if (quantities) {
        updateQte_legacy(idProduit, color, size, quantities);
    }

    checkProductInWishlist();
}

// Updates visual and thumbnail for selected color
function updateColor_legacy(idProduit) {

    var item_url = $(this).attr('data-itm-img'),
        itm_id = $(this).attr('data-produit_id'),
        color_url = path_relative_root + 'img/couleur/' + $(this).val() + '.jpg';

    if ($(this).val() != '') {
        $('#img_produit_' + idProduit).attr('src', item_url);

        //Changement de l'id produit au si different
        $('#produit_id_' + idProduit).val(itm_id);
        $('#produit_id_' + idProduit).attr('name', 'produit_id_' + itm_id);
    }

}

// Disabled sizes without stock for selected color
function setAvailableSizes_legacy(idProduit) {

    var eltaille = document.getElementById('tailleProd_' + idProduit),
            eltaille_onchange = eltaille.getAttribute("onchange"),
            color = document.getElementById('couleurProd_' + idProduit).value;

    if (eltaille)
    {
        var response = ajax_file(path_relative_root + 'ajax_liste_taille.php?idprod=' + idProduit + '&idColor = ' + color);

        if (response)
        {
            eltaille.parentNode.innerHTML = response;
            $('#tailleProd_' + idProduit).addClass("input_select size").removeAttr("style");
            $('#tailleProd_' + idProduit).attr("onchange", eltaille_onchange);
        }
    }
}

function updateQte_legacy(idProduit, color, size, quantities) {

    var dataParam = {}, string_params, no_stock = Translator.translate('product_no_stock_2');

    if ($('#lot_nb_prod_' + idProduit).length) {
        dataParam = {idprod: idProduit, idcouleur: color, idtaille: size, produit_lot_nb: $('#lot_nb_prod_' + idProduit).val()};
        string_params = '?idprod=' + idProduit + '&idcouleur=' + color + '&idtaille=' + size + '&produit_lot_nb' + $('#lot_nb_prod_' + idProduit).val();
    } else {
        dataParam = {idprod: idProduit, idcouleur: color, idtaille: size};
        string_params = '?idprod=' + idProduit + '&idcouleur=' + color + '&idtaille=' + size;
    }

    var response = ajax_file(path_relative_root + 'ajax_liste_qte_alerte.php' + string_params);

    if (response) {
        quantities.parent().html(response);
        var new_qte    = $('#qteProd_' + idProduit);

        if( new_qte.length === 1 && new_qte.val() === ""){
            $('#add_basket_wrapper', '#ref_look_' + idProduit).hide();
            $('.btn_add_alert', '#ref_look_' + idProduit).show();
            $('.search_store', '#ref_look_' + idProduit).hide();
            $('.search_store', '#ref_look_' + idProduit).css("margin-top", "10px");
        }
        else {
            $('#add_basket_wrapper', '#ref_look_' + idProduit).show();
            $('.btn_add_alert', '#ref_look_' + idProduit).hide();
            $('.search_store', '#ref_look_' + idProduit).show();
            $('.search_store', '#ref_look_' + idProduit).css("margin-top", "");
        }
    }
}

// ERESA
/*
 * Gestion des point de vente dans la fiche produit
 * @author Aurélien Renault
 */
var Eresa = {

    boxName: 'e-resaBox',
    box: $('#e-resaBox'),
    content: $('#content_e-resaBox'),
    stores: {}, // La liste de tous les magasin ereservation
    storeSelected: {}, // Le magasin sélectionné
    places: {}, // Google Place
    storeHtml: '', // Le template des magasins
    step: 0, // Le template des magasins
    product: {
        product_id : '',
        color_id : '',
        size_id : ''
    }, // les info du produit
    nbKilometerMax: eresa_nb_km_max,

    // Permet de charger tous les magasins en e-resa (etape 2). Ici pas de carte
    storeInit: function() {
        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            Mustache.parse(Eresa.storeHtml);
        }else{

            // Initialisation du template des stores
            if (this.storeHtml === '') {
                this.storeHtml = $('.store_locator .store')[0].outerHTML;
            }
            Mustache.parse(this.storeHtml);
        }

        var data = {
            'product_id' : Eresa.product.product_id,
            'color_id' : Eresa.product.color_id,
            'size_id' : Eresa.product.size_id,
            'version' : 2,
        };

        var self = this;
        $.ajax({
            type : 'post',
            url : path_relative_root + 'ajax_get_store_ereservation.php',
            data: data,
            success : function (boutique_bookinstore) {

                if (boutique_bookinstore) {
                    self.stores = $.parseJSON(boutique_bookinstore);

                    if (!disable_use_geolocation) {
                        // Pour la restriction google
                        var countries = [];
                        var select = $('select[name="country"]', Eresa.box);
                        var store = '';
                        var length = self.stores.length;
                        // Construction de la liste des pays disponibles
                        for (var i = 0 ; i < length ; i++) {
                            store = self.stores[i];
                            var doublon = countries.find(function (val) {
                                return val === store.iso;
                            });
                            if (doublon === undefined) {
                                countries.push(store.iso);
                                // Pour la construction du select
                                var option = '<option value="' + store.id + '">' + store.country + '</option>';
                                select.append(option);
                            }
                        }

                        // Le trigger sur le select pour bien sélectionner le produit (car le select utilise un plugin)
                        select.trigger('change');

                        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
                            navigator.geolocation.getCurrentPosition(function (position) {
                                var pos = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude
                                };

                                if (pos.lat>0 && pos.lng>0) {
                                    self.storeOnPlaceChange(pos);
                                }
                            });

                            var options = {
                                types: ['geocode'],
                                style: google_map_style // dans le cas du chargement de la carte
                            };

                            if (countries && countries.length <= 5) {
                                options.componentRestrictions = {
                                    country: countries
                                };
                            }
                        }else{


                            if (google_map_style !== undefined) {
                                var options = {
                                    types: ['geocode'],
                                    style: google_map_style // dans le cas du chargement de la carte
                                };
                            } else {
                                var options = {
                                    types: ['geocode']
                                };
                            }
                        }

                        if (countries && countries.length <= 5) {
                            options.componentRestrictions = {
                                country: countries
                            };
                        }

                        var eltAutoAdresse = document.getElementById('eresa_autoAddress');
                        // Initialisation de l'autocomplete
                        self.places = new google.maps.places.Autocomplete(eltAutoAdresse, options);

                        google.maps.event.addListener(self.places, 'place_changed', self.storeOnPlaceChange.bind(self));
                        google.maps.event.addDomListener(eltAutoAdresse, 'keydown', function(evt) {
                            // On bloque la touche enter
                            if (evt.keyCode === 13) {
                                evt.preventDefault();
                            }
                        });
                    } else {
                        var storeLocator = $('.store_locator');

                        storeLocator.removeClass('loaded');
                        self.generateStoreBloc(self.stores);

                        // On signale que tous les magasin ont été chargé
                        storeLocator.addClass('loaded');
                        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
                            if (storeLocator.overlayScrollbars && {}.toString.call(storeLocator.overlayScrollbars) === '[object Function]') {
                                storeLocator.overlayScrollbars({});
                            }
                        }
                    }

                    // On "active" la nouvelle étape
                    $('.step, .step_num').removeClass('actif');
                    $('#step' + Eresa.step + ', .step_num[data-step=' + Eresa.step + ']').addClass('actif');
                } else {
                    alert(Translator.translate('no_store_ereservation'))
                }
            }
        });
    },
    /**
     * Event quand on change d'adresse
     */
    storeOnPlaceChange: function(auto_location) {

        var storeLocator = $('.store_locator');
        var place = this.places.getPlace();


        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            if (typeof(auto_location) != 'undefined') {
                var latitude = auto_location.lat;
                var longitude = auto_location.lng;

            } else {
                var place = this.places.getPlace();
                var latitude = place.geometry.location.lat();
                var longitude = place.geometry.location.lng();
            }
        }else{

            // Récupération de la latitude/longitude de la place trouvé pour le calcul de la distance avec tous les magasins
            var latitude = place.geometry.location.lat();
            var longitude = place.geometry.location.lng();
        }
        var distance = 0;

        storeLocator.removeClass('loaded');

        var storesOrdonnee = []; // La liste de tous les magasin ereservation ordonné par distance
        // On va parcourir chaque magasin trouvé pour calculer la distance entre le magasin et la position de l'adresse
        $.each(this.stores, function(index, store) {
            // Si la latitude exist
            if (store.lat != 0) {
                // Calcul de la distance avec formule haversine
                // Le calcul est le même que dans la fonction du BO en BDD)
                var radlon1 = (longitude * Math.PI) / 180;
                var radlon2 = (store.lng * Math.PI) / 180;
                var radlat1 = (latitude * Math.PI) / 180;
                var radlat2 = (store.lat * Math.PI) / 180;
                var dlo = (radlon2 - radlon1) / 2;
                var dla = (radlat2 - radlat1) / 2;
                var a = Math.sin(dla) * Math.sin(dla) + Math.cos(radlat2) * Math.sin(dlo) * Math.sin(dlo);
                distance = 6371 * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); // 6371 rayon de la terre
                store.distance = Math.round(distance * 100) / 100;
            }
            var length = storesOrdonnee.length;
            if (length > 0) {
                // Maintenant on va ordonner la liste des magasins au fur et à mesure
                for (var i = 0 ; i < length ; i++) {
                    // Si la valeur est plus petite que le premier element
                    if (store.distance < storesOrdonnee[i].distance) {
                        storesOrdonnee.splice(i, 0 , store);
                        break;
                    } else if (storesOrdonnee[i+1] !== undefined) { // si on a un élément suivant
                        // Si la distance du magasin est supérieur au courant et inférieur au suivant alors on a trouvé sa place
                        if (store.distance > storesOrdonnee[i].distance && store.distance < storesOrdonnee[i+1].distance) {
                            storesOrdonnee.splice(i+1, 0 , store);
                            break;
                        }
                    } else { // si plus d'élement suivant on met la valeur à la fin
                        storesOrdonnee.push(store);
                    }
                }
            } else {
                storesOrdonnee.push(store);
            }
        });

        // Maintenant qu'on a les magasin dans l'ordre de leur distance on va générer les blocs
        this.generateStoreBloc(storesOrdonnee);

        // On signale que tous les magasin ont été chargé
        storeLocator.addClass('loaded');
        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            storeLocator.overlayScrollbars({});
        }
    },
    // Pour générer les bloc des magasins
    generateStoreBloc: function(stores) {
        var self = this;
        var storeLocator = $('.store_locator');

        // On vide le bloque
        storeLocator.html('');
        // Les magasins avec au moin 1 stock
        var nbStoreAvailable = 0;
        // Les magasins assez proche
        var nbStoreAvailableNear = 0;
        $.each(stores, function(index, store) {
            // On regarde si il y a un horaire de renseigner
            if (store.schedule !== '') {
                // On va mettre la class actif
                store.showSchedule = 'actif';
            } else {
                store.showSchedule = '';
            }
            store.showQuantity = '';
            store.S_article = '';
            store.styleNoStock = 'display:none !important;';
            store.avaibility_article = '';

            if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
                if(store.quantity==null) {
                    store.quantity = 0;
                }
            }

            if (store.quantity > 2) {
                // Juste pour mettre un s à articles
                store.S_article = 's';
            }
            store.showQuantity = 'actif';

            if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
                if (store.quantity == 0) {
                    store.showQuantity = 'no_stock';
                    store.styleButton = 'display:none !important;';
                    store.styleNoStock = 'display:block;';
                    store.hide_text = 'display:none;';
                    store.show_text = 'display:inline-block;';
                    store.show_text_avaibility = 'display:none;';
                }

                if (store.quantity >= 1) {
                    store.show_text_avaibility = 'display:none;';
                    store.hide_text = 'display:inline-block;';
                    store.show_text = 'display:none;';
                    store.showQuantity = 'actif';
                }
            }

            // Si il n'y a pas assez de stock alors on n'affiche pas le magasin
            if (store.quantity > 0 || typeof(eresa_no_stock) != 'undefined') {
                nbStoreAvailable ++;
                if (store.distance <= self.nbKilometerMax || disable_use_geolocation) {
                    var html = Mustache.render(
                        self.storeHtml,
                        store
                    );
                    $(html).appendTo(storeLocator);
                    nbStoreAvailableNear ++;
                }
            }
        });

        var report = $('<div>', {class: 'js_store_report'});
        if (nbStoreAvailable === 0 || nbStoreAvailableNear === 0) {
            if (nbStoreAvailable === 0) {
                report.html(Translator.translate('store_not_available_quantity'));
            } else if (nbStoreAvailableNear === 0) {
                report.html(Translator.translate('store_not_available_near'));
            }
            $(report).appendTo(storeLocator);
        }

        // L'event pour afficher les horaires
        $('.js_have_horaire_store.actif', storeLocator).on('click', function(event) {
            event.preventDefault();
            var sheduleStore = $(this).next('.schedule_store');
            // Pour afficher les horaires du magasins
            if (!sheduleStore.hasClass('actif')) {
                sheduleStore.addClass('actif');
            } else {
                sheduleStore.removeClass('actif');
            }
        });
    },
    // Dans le cas où on change de couleur
    onChangeColor: function() {
        var color = $('input[name=itm_color]:checked', Eresa.box);
        var size = $('input[name=itm_color]:checked', Eresa.box);
        var productId = color.data('productid');
        var data = {
            'produit_id': productId,
            'couleur_id': color.val(),
            'taille_id': size.val(),
        };
        if (color.data('regroupement') === 1) {
            // Chargement du nouveau produit en cas de regroupement
            this.loadStepEresa(data, 1);
        } else {
            // Changement de l'image
            $('#js_product_bookinstore_img').attr('src', color.data('pic'))
        }
    },
    // Permet de récupérer toutes les informations du produits
    /**
     *
     * @param data
     * @param statut => le statut de chargement (0, 1) les chiffres sont les numéros d'étape (0 => init)
     */
    loadStepEresa: function(data, statut) {
        // Chargement
        $('.steps', Eresa.box).addClass('loading');
        $.ajax({
            url: path_relative_root + 'ajax_bookinstore_produit.php',
            type: 'POST',
            data: data,
            success: function(response){
                try {
                    response = $.parseJSON(response);
                } catch(e){
                    console.error('parseJSON');
                    return;
                }

                if (response.success === 'ok'){

                    var datas = response.datas;
                    var html = $(datas.html);

                    if (statut === 0) {
                        Eresa.content = $('#content_e-resaBox');
                        Eresa.box = $('#e-resaBox');
                        Eresa.content.html(html);
                        // On va déplacer le formulaire de vue JS au bon endroit
                        $('#js_form_infos_bookinstore_vue').append($('#form_eresa'));

                        // Ouverture de la ligthbox
                        modBoxOpen.call(Eresa.box);
                    } else if (statut === 1) {
                        var step1 = $('#step1', html).html();
                        var step4 = $('#step4', html).html();
                        $('#step1', Eresa.content).html(step1);
                        $('#step4', Eresa.content).html(step4);
                    }
                    // Chargement
                    $('.steps', Eresa.box).removeClass('loading');

                } else {
                    alert(response.error.msg);
                }
            },
            complete: function(){
                if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
                    $('html, body').animate({ scrollTop: 0 }, $(document).scrollTop() * 0.8);

                    // enveloper taille et couleur dans le div "size-color-wrap"
                    // pour pouvoir ajouter le css "display:flex"
                    $('#e-resaBox.lightbox #step1 .product_info .ligne_form').wrapAll( "<div class='size-color-wrap' />");

                    // Initialisation du template des stores
                    if (Eresa.storeHtml === '') {
                        Eresa.storeHtml = $('.store_locator .store')[0].outerHTML;
                    }

                    if (!Eresa.checkProduct()) {
                        btnLoaderUnloading($("#step1 .form_submit").find(':first'));
                        return false;
                    }

                    Eresa.storeInit();
                }else{
                    $('html, body').animate({ scrollTop: 0 }, $(document).scrollTop() * 0.8);
                }
            }
        });
    },
    // Pour vérifier les information du produit
    checkProduct: function() {
        var error_color = false;
        var error_size = false;

        if (typeof $('input[name=itm_color]:checked', Eresa.box).val() === 'undefined') {
            error_color = true;
        }
        if ((!force_eresa && typeof $('input[name=itm_size]:checked', Eresa.box).val() === 'undefined') || (force_eresa && typeof $('#prod_taille').val() === 'undefined')) {
            error_size = true;
        }

        if (error_color && error_size) {
            alert(translate('choose_a_size_and_a_color'));
            return false;
        } else if (error_color) {
            alert(translate('js_error_couleur'));
            return false;
        } else if (error_size) {
            alert(translate('js_error_size'));
            return false;
        }
        var color = $('input[name=itm_color]:checked', Eresa.box);
        if (force_eresa) {
            var size = $('input[name=itm_size][value=' + $('#prod_taille').val() + ']', Eresa.box);
            size.prop('checked', true);
        } else {
            var size = $('input[name=itm_size]:checked', Eresa.box);
        }
        $('#TailleIdBookInStore').val(size.val());
        $('#CouleurIdBookInStore').val(color.val());
        $('#ProduitIdBookInStore').val(color.data('productid'));

        Eresa.product.product_id = color.data('productid');
        Eresa.product.color_id = color.val();
        Eresa.product.size_id = size.val();

        // On va attribuer les valeurs pour le récapitulatif (taille et couleur)
        $('#js_color').html(color.data('name'));
        $('#js_size').html(size.data('name'));
        return true;
    },
    // Pour vérifier les informations du magasin
    checkStore: function(btn) {
        var self = this;
        $('#StoreIdBookInStore').val($(btn).data('magasinid'));
        // Enregistrement des informations du magasin (pour l'étape récapitulatif)
        // On va rechercher le magasin selectionné dans la liste
        $.each(this.stores, function(index, store) {
            if (store.id === $(btn).data('magasinid')) {
                self.storeSelected = store;
            }
        });
        return true;
    },

    // Pour vérifier les informations du magasin
    checkClient: function() {

        // Récupération du formulaire
        var form = $('#js_form_eresa');

        var error = false;
        var elt = '';
        // Check de tous les champs obligatoire
        $('.required', form).each(function() {
            elt = $(this);
            if (this.getAttribute('type') === 'checkbox') {
                if (!elt.is(':checked')) {
                    elt.parent().addClass('inputErr');
                    error = true;
                } else {
                    elt.parent().removeClass('inputErr');
                }
            } else if (this.getAttribute('type') === 'radio') {
                var name = elt.attr('name');
                if ($('input[name="' + name + '"]:checked').length === 0) {
                    elt.parent().addClass('inputErr');
                    error = true;
                } else {
                    elt.parent().removeClass('inputErr');
                }
            } else if (this.getAttribute('type') === 'email') {
                var validateEmail = String(this.value)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
                if (elt.val() === '' || !validateEmail) {
                    elt.addClass('inputErr');
                    error = true;
                } else {
                    elt.removeClass('inputErr');
                }
            } else if ( this.getAttribute( 'type' ) === 'tel' ) {
                error = !checkForm('phone',this,'telephone');
            } else {
                if (elt.val() === '') {
                    elt.addClass('inputErr');
                    error = true;
                } else {
                    elt.removeClass('inputErr');
                }
            }
        });
        return !error;
    },
    next: function() {
        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {

            if(Eresa.step > 0) {
                // cache l'element "popup_desc" quand c'est pas etape 1
                $('#content_e-resaBox .popup_desc').css('display', 'none');
            }
            // Verification que la couleur et la taille ont bien été choisie
            if(Eresa.step === 2) { // Choix du magasin
                if (!Eresa.checkStore(this)) {
                    return false;
                }
            } else if(Eresa.step === 3) { // Formulaire
                // La vérification du formulaire est faite dans le vuejs (w-pdv-form)
                if (!Eresa.checkClient(this)) {
                    $(this).next('.loader').removeClass('loading');
                    return false;
                }
            }

            // On attend d'avoir la reponse car la prochaine étape ne doit pas encore s'afficher
            if (Eresa.step!=3) {


                // On "desactive" l'ancienne étape
                $('#step' + Eresa.step).removeClass('actif');

                // ONGLET : On "desactive" l'ancienne étape
                $('.step_num[data-step='  + Eresa.step + ']').removeClass('actif');
            }

            Eresa.step++;
            // On "active" la nouvelle étape
            if (Eresa.step!=4) {
                $('#step' + Eresa.step).addClass('actif');
            }

        }else{
            // Verification que la couleur et la taille ont bien été choisie
            if (Eresa.step === 1) {
                if (!Eresa.checkProduct()) {
                    $(this).next('.loader').removeClass('loading');
                    return false;
                }
            } else if(Eresa.step === 2) { // Choix du magasin
                if (!Eresa.checkStore(this)) {
                    $(this).next('.loader').removeClass('loading');
                    return false;
                }
            } else if(Eresa.step === 3) { // Formulaire
                if (!Eresa.checkClient(this)) {
                    $(this).next('.loader').removeClass('loading');
                    return false;
                }
            }

            // On "desactive" l'ancienne étape
            $('#step' + Eresa.step).removeClass('actif');

            // ONGLET : On "desactive" l'ancienne étape
            $('.step_num[data-step='  + Eresa.step + ']').removeClass('actif');

            Eresa.step++;

        }

        if (Eresa.step!=4) {
            // ONGLET : On "active" la nouvelle étape étape
            $('.step_num[data-step='  + Eresa.step + ']').addClass('actif');
        }

        // Au chargement de la 2eme étape on charge les magasin
        if (Eresa.step === 2) {
            if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            }else{
                Eresa.storeInit();
            }
        } else if (Eresa.step === 4) { // validation du choix et envoi des informations
            var form = $('#js_form_eresa').serialize();
            $("#error_limit_quota").hide();
            $("#js_form_eresa button").hide();
            $.ajax({
                url: path_relative_root + 'ajax_bookinstore_client.php',
                type: 'POST',
                data: form,
                dataType: 'json',
                success: function (response){
                    if (response.success) {
                        $('#step' + (Eresa.step-1)).removeClass('actif');

                        // ONGLET : On "desactive" l'ancienne étape
                        $('.step_num[data-step='  + (Eresa.step-1) + ']').removeClass('actif');

                        // ONGLET : On "active" la nouvelle étape étape
                        $('.step_num[data-step='  + Eresa.step + ']').addClass('actif');
                        $('#step' + Eresa.step + ' .confirm').css('display', 'block');
                    } else {
                        if (response.type_error=='limit_exceeded') {
                            Eresa.step--;
                            $("#error_limit_quota").show();
                            $("#error_limit_quota span").html(response.error_msg);
                        } else {
                            $('#step' + Eresa.step + ' .confirm').css('display', 'none');
                            $('#step' + Eresa.step + ' .error').html(response.error_msg).css('display', 'block');
                        }
                    }

                    // On va attribuer les valeurs pour le récapitulatif (taille et couleur)
                    $('#js_color').html(response.product.color.name);
                    $('#js_size').html(response.product.size.name);

                    // Attribution des information du magasin
                    var html = Mustache.render(
                        Eresa.storeHtml,
                        Eresa.storeSelected
                    );
                    $('#js_eresa_store_choice').html(html);

                    // L'event pour afficher les horaires
                    $('.js_have_horaire_store.actif', $('#js_eresa_store_choice')).on('click', function(event) {
                        event.preventDefault();
                        var sheduleStore = $(this).next('.schedule_store');
                        // Pour afficher les horaires du magasins
                        if (!sheduleStore.hasClass('actif')) {
                            sheduleStore.addClass('actif');
                        } else {
                            sheduleStore.removeClass('actif');
                        }
                    });

                    // On "active" la nouvelle étape
                    $('#step' + Eresa.step).addClass('actif');
                }
            });
        } else {
            // On "active" la nouvelle étape
            $('#step' + Eresa.step).addClass('actif');
        }
        $(this).next('.loader').removeClass('loading');
    },
    // Montre la lightbox des points de ventes
    show: function() {
        var produitId = $('#produit_id').val();

        var couleurId = $('#prod_couleur').val();
        if (!couleurId) {
            couleurId = $('#prod_couleur').attr('data-id');
        }
        var tailleId = $('#prod_taille').val();
        var data = {
            'produit_id': produitId,
            'couleur_id': couleurId,
            'taille_id': tailleId,
        };
        var missing_value = (!data.produit_id || !data.couleur_id || !data.taille_id);
        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true && missing_value) {
            alert(translate('js_error_size'));
            return false;
        }
        // Chargement du produit dans la lightbox
        Eresa.loadStepEresa(data, 0);

        Eresa.step = 1;

        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            // On ne laisse plus le choix des tailles / couleur deja fait avant
            Eresa.next();
        }

    },
    // Cache la lightbox des points de ventes
    close: function() {

        modBoxClose();
        setTimeout(function() {Eresa.RAZ()}, 1000);
    },

    // Remet à 0 les step
    RAZ: function() {
        // On revient à l'étape 1
        Eresa.step = 1;
        // On désactive tout
        $('.step').removeClass('actif');
        $('.step_num').removeClass('actif');
        // On active l'étape 1
        $('#step' + Eresa.step).addClass('actif');
        // ONGLET : On "active" l'étape 1
        $('.step_num[data-step='  + Eresa.step + ']').addClass('actif');
    }
}

function loadProduct(type_filter, id, type_page, itm_rr_id) {

    var tmp_qte = $('#qteProd_' + id).val();
    var is_wishlist = false;
    var is_achat_express_v2 = (this.form !== undefined && this.form.elements['achatExpressV2'] !== undefined && !is_wishlist);
    var isProdKit = (typeof(produit_kit_id) !== 'undefined'); // permet de reload le bloc d'image pour afficher les différentes images de chacun des produits du kit

    if (isProdKit) {
        id_prod = $(this).attr('data-idprod-img');
    } else if (typeof (id) == 'undefined') {
        id_prod = "";
    } else {
        id_prod = "_" + id;
    }

    if (typeof (type_page) == 'undefined') {
        type_page = 'product';
    }

    var $form = '';
    var data = '';


    if (!is_achat_express_v2) {
        $form = $('#item_add_form');
        var $input = $('#prod_' + type_filter + id_prod, $form);
        var type = type_filter;
        var $hidden = $('#prod_' + type_filter + id_prod, $form);

        if (typeof (id) == 'undefined') {
            data += $form.serialize();
        }

        var couleur_id = 0;
        if (isProdKit) {
            couleur_id = $(this).attr('value');
            $produit_id_new = $(this).attr('data-idprod-img');
        } else {
            couleur_id = $("#prod_couleur").val();
            $produit_id_new = $('#prod_' + type_filter + id_prod).find(':selected').attr('data-produitid');
        }

        data += '&couleurProd=' + couleur_id;
        data += '&tailleProd=' + $("#prod_taille").val();
        data += '&type=' + type_filter;
        data += '&type_page=' + type_page;
        if (document.getElementById('wishlist')) {
            data += '&type_page=' + type_page;
        }

        if (typeof (id) != 'undefined') {
            data += '&couleurProd=' + $('#couleurProd' + id_prod).val();
            data += '&tailleProd=' + $('#tailleProd' + id_prod).val();
            data += '&produit_principal=' + $('#produit_principal' + id_prod).val();
            var produit_principal = $('#produit_principal' + id_prod).val();
        }

        data += '&produit_id_new=' + $produit_id_new;

        var div_produit_achat_express_v2 = $(this).parents('.swiper-slide');

    } else {
        var div_produit_achat_express_v2 = $(this).parents('.swiper-slide');
        var swiper_achat_express_v2 = $(this).parents('.swiper-container');

        $form = $(this.form);
        var id_prod = "";
        $form = this.form;

        var old_id = this.form.elements['produit_id'].value;
        var isCategSuper = this.form.elements['isCategSuper'].value;
        var new_id = id;
        var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';

        var couleur_id = 0;
        if (isProdKit) {
            couleur_id = $(this).attr('value');
            new_id = $(this).attr('data-idprod-img');
        } else {
            couleur_id = $(this).val();
        }

        data += 'produit_id=' + old_id;
        data += '&produit_principal=' + old_id;
        data += '&couleurProd=' + couleur_id;
        data += '&tailleProd=';
        data += '&qteProd=1';
        data += '&hdn_product_id=' + old_id;
        data += '&produit_id_new=' + new_id;
        data += '&type=' + type_filter;
        data += '&type_page=' + type_page;
        data += '&is_achat_express=0';
        data += '&is_achat_express_v2=1';
        data += '&cpt_prod=' + (cpt_prod - 1);
        data += '&isCategSuper=' + isCategSuper;
    }

    $.ajax({
        type: 'post',
        url : path_relative_root + create_link("ajax_get_product_regroup_ref"),
        data: data,
        beforeSend: function () {
            $('#bloc_btn_loader').show();
            $('#btn_error').fadeOut(300);
        },
        success: function ( response ) {
            try {
                response = $.parseJSON(response);
            }
            catch (e) {
                console.error('parseJSON');
                return;
            }

            if (response.success == 'ok') {

                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'view_item',
                    });
                }

                var datas = response.datas;

                if (type_page == 'product') {

                    var $html_main = $(datas.html_main);
                    if (isProdKit) {
                        //set slider product
                        $("#zoom_container").replaceWith($("#zoom_container", datas.html_main));

                        initSwiperZoomContainer();
                    } else if (is_achat_express_v2) {
                        // Photo et information
                        div_produit_achat_express_v2.replaceWith($html_main);
                        if ($('#oneProduct') != undefined && $('#oneProduct').hasClass('actif')) {
                            $('.itm_achat_express_' + new_id).addClass('full');
                        }
                        if ($('.container_products_lookbook').length > 0) {
                            //Evite de casser le style pour kujten
                            if ($('#is_from_kujten') == undefined) {
                                $html_main.removeClass('item_container');
                                $html_main.addClass('item');
                            }
                        }

                        if (swiper_achat_express_v2) {
                            if (typeof (four_images) != 'undefined') {
                                enableSwiperFourImage();
                            } else {
                                var id_swiper = swiper_achat_express_v2.attr('id');
                                refreshSliderAchatExpress(id_swiper);
                            }
                        }

                        setTimeout(function () {
                            initItemSlider(false);
                            if ($('.assoSlider').length) {
                                $('.assoSlider').each(function () {
                                    this.swiper.update();
                                });
                            }
                        }, 200);
                    } else {

                        var $fiche = $('#contents_wrapper');
                        var replace_main = new Array(
                            '#wrapper_product_name',
                            '#zoom_container',
                            '.prod_page_bot',
                            '.compo',
                            '.associations_section',
                            '.accordion_container',
                            '.product_infos .price_container',
                            '.cms-page-module',
                            'w-netreviews-stars',
                            'w-netreviews-comments',
                            '.description_courte',
                            '.caracNav',
                            '.resume',
                            '.netreviews_reviews',
                            '#estimated_delivery'
                        );

                        var product_refext_prod = $('input[name=itm_refext_prod]', $html_main).val();

                        for (var i = 0; i < replace_main.length; i++) {
                            var id = replace_main[i];

                            if (id == '.accordion_container') {
                                // Recover each accordion_item id so we can replace each one individually
                                $('.accordion_item', $fiche).each(function () {
                                    var acc_item_id = $(this).attr('id');

                                    if ($("#" + acc_item_id, $html_main).length) {
                                        // Is item was previously hidden, remove cache class
                                        if ($(this).hasClass('cache')) {
                                            $(this).removeClass('cache')
                                        }

                                        // Update item content in fiche with ajax response content
                                        $(this).html($("#" + acc_item_id, $html_main).html());
                                    } else {
                                        // If fiche accordion item isn't present in ajax response, hide it by adding cache class
                                        $(this).addClass('cache');
                                    }
                                });

                            } else if (id == '.description_courte') {
                                // // Bloc pas accessible directement sur html_main (méthodo juste au-dessus), obligé de contourner avec cette méthode - EX : Messegue
                                $html_main.each(function () {
                                    if ($(this).hasClass('description_courte')) {
                                        $('.description_courte', $fiche).html($(this).html());
                                    }
                                });
                            } else if (id == '.caracNav') {
                                // Example : Sabon FP Description --> Item description isn't build as an accordion like most wshops
                                // Description is working as tabs

                                // Titles
                                $('.caracLabel', $html_main).each(function () {
                                    var item_id = $(this).attr('id');
                                    $("#" + item_id, $fiche).html($(this).html());
                                });

                                // Contents matching titles
                                $('.caracWrapper', $html_main).each(function () {
                                    var item_id = $(this).attr('id');
                                    $("#" + item_id, $fiche).html($(this).html());
                                });

                            } else if (id == 'w-netreviews-stars') { // New Avis verifies
                                $("#w-netreviews-stars", $fiche).html($('w-netreviews-stars', $html_main).html());
                                // Reload vuejs avis verifies
                                if (typeof wShop !== 'undefined' && wShop.$refs.wNetreviewsStars) {
                                    // Avis verifies stars
                                    if (wShop.$refs.wNetreviewsStars != 'undefined') {
                                        wShop.$refs.wNetreviewsStars.reload(product_refext_prod);
                                    }
                                }
                            } else if (id == 'w-netreviews-comments') { // New Avis verifies
                                $("#w-netreviews-comments", $fiche).replaceWith($('w-netreviews-comments', $html_main).wrap('<p/>').parent().html());
                                // Reload vuejs avis verifies
                                if (typeof wShop !== 'undefined' && wShop.$refs.wNetreviewsComments) {
                                    // Avis verifies reviews
                                    if (wShop.$refs.wNetreviewsComments != 'undefined') {
                                        wShop.$refs.wNetreviewsComments.reload(product_refext_prod);
                                    }
                                }
                            } else if (id == '#estimated_delivery') {
                                // Réinjection du bloc estimated_delivery dans la fiche produit, car élément déplacer de base en js (voir plus bas) il faut donc le réinjecter
                                $html_main.each(function () {
                                    if ($(this).attr('id') == 'estimated_delivery') {
                                        let container = $(this)[0].outerHTML;
                                        $(container).insertBefore($('#add_basket_wrapper'));
                                    }
                                });
                            } else {
                                // In case info is not present in response, remove it (ex: cms-page-module on one ref but not on an other)
                                if ($(id, $fiche).length && $(id, $html_main).length) {
                                    $(id, $fiche).html($(id, $html_main).html());
                                } else {
                                    if ($(id, $fiche).length && !$(id, $html_main).length) {
                                        $(id, $fiche).replaceWith('');
                                    }
                                }

                                /******* OLD AVIS VERIFIES ************/
                                if (id == '.netreviews_reviews') {
                                    if ($('#avis_verifie_produit').length || $('.netreviews_reviews').length || $('.netreviews_stars').length) {
                                        if (netreviews_product_url !== null ) {
                                            // netreviews_product_url defined in views_mobile_new/main.php
                                            (function () {
                                                var script = document.createElement('script');
                                                script.setAttribute('id', 'ajax_verified');
                                                script.setAttribute("type", "text/javascript");
                                                // script.setAttribute("src", "//cl.avis-verifies.com/fr/cache/1/7/d/17d1a320-136f-2ca4-4553-bcc2817af7ba/widget4/17d1a320-136f-2ca4-4553-bcc2817af7baproduit_script.js");
                                                script.setAttribute("src", netreviews_product_url);

                                                // If ajax already being done, verify if script exists before adding it to HEAD
                                                if (document.getElementById('ajax_verified') !== null) {
                                                    document.querySelector('#ajax_verified').remove();
                                                }

                                                document.getElementsByTagName("head")[0].appendChild(script);
                                            })();
                                        }
                                    }

                                    if (typeof nrSearchForStars !== "undefined" && $('.NETREVIEWS_PRODUCT_STARS').length) {
                                        nrSearchForStars();
                                    }

                                    // Display avis verifie comments
                                    // Call nrSearchForReviews from tag.min.js avis verifie NETREVIEWS_PRODUCT_URL
                                    if (typeof nrSearchForReviews !== "undefined" && $('.NETREVIEWS_PRODUCT_REVIEWS').length) {
                                        nrSearchForReviews();
                                        setTimeout(function() {
                                            if ($('#netreviews_reviews_tab').height() === undefined) {
                                                $("#avis_verifies").hide();
                                            } else {
                                                $("#avis_verifies").show();
                                            }
                                        }, 1500);
                                    }
                                }
                            }
                        }

                        /******* ASSOCIATED PRODUCTS ************************************/
                        /***************************************************************/

                        var slides_per_view = typeof associatedProduct_spv != "undefined" ? associatedProduct_spv : 1;
                        var asso_btn_prev = typeof assoBtnPrev != "undefined" ? assoBtnPrev : ".swiper-button-prev, .assos_product .assos-product-prev, .assos-rayon-prev";
                        var asso_btn_next = typeof assoBtnNext != "undefined" ? assoBtnNext : ".swiper-button-next, .assos_product .assos-product-next, .assos-rayon-next";
                        var asso_space_between = typeof assoSpaceBetween != "undefined" ? assoSpaceBetween : 24;
                        var asso_swiper_params = typeof assoSwiperParams != "undefined" ? assoSwiperParams : {
                            slidesPerView: slides_per_view,
                            spaceBetween: asso_space_between,
                            nextButton: asso_btn_next,
                            prevButton: asso_btn_prev,
                        };

                        // Associations_section = tabs (=> onglets)
                        if ($('.associations_section', $html_main).length > 0) {

                            // Check if associated products are displayed as tabs
                            if ($('.product_associations_wrapper', $html_main).attr('data-tab') !== undefined) {
                                // First check if there's an active class on each titles and wrappers
                                if (!$('.association_title.active').length) {
                                    $('.association_title').first().addClass('active');
                                }

                                if (!$('.product_associations_wrapper.active').length) {
                                    $('.product_associations_wrapper').first().addClass('active');

                                    // Init also swiper on page load
                                    if ($('.product_associations_wrapper.active .swiper-container .swiper-slide').length > Math.floor(asso_swiper_params.slidesPerView)) {
                                        new Swiper('.product_associations_wrapper.active .swiper-container', asso_swiper_params)
                                    }
                                }

                                // Once first title and first swiper is active, handle click on each tabs dynamically
                                $('.association_title').on('click', function () {

                                    var title_tab = $(this).attr('data-tab');
                                    var active_tab = $('.product_associations_wrapper.active');

                                    // If the clicked title is not active yet, set it to active and remove active class from the last title
                                    if (!$(this).hasClass('active')) {
                                        $('.association_title.active').removeClass('active');
                                        $(this).addClass('active');
                                    }

                                    // If new active title, display matching associations tab
                                    if (title_tab != active_tab.attr('data-tab')) {
                                        active_tab.removeClass('active');
                                        $('.product_associations_wrapper[data-tab="' + title_tab + '"]').addClass('active');

                                        // Swiper activation
                                        if ($(".swiper-container", '.product_associations_wrapper[data-tab="' + title_tab + '"]')) {
                                            // If more than slides_per_view, we activate the swiper
                                            if ($('.product_associations_wrapper[data-tab="' + title_tab + '"] .swiper-slide').length > Math.floor(asso_swiper_params.slidesPerView)) {
                                                setTimeout(new Swiper('.product_associations_wrapper[data-tab="' + title_tab + '"] .swiper-container', asso_swiper_params),500);
                                            }
                                        }
                                    }
                                });

                            }
                        } else {

                            // Init product_associations_wrapper swiper(s)
                            if ($('.product_associations_wrapper').length > 1) {

                                $('.product_associations_wrapper').each(function() {
                                    if ($(this).find('.swiper-slide').length >= Math.floor(asso_swiper_params.slidesPerView)) {
                                        new Swiper(($(this).find('.swiper-container')), asso_swiper_params)
                                    }
                                });
                            } else if ($('.product_associations_wrapper').length > 0) {
                                if ($('.product_associations_wrapper').find('.swiper-slide').length >= Math.floor(asso_swiper_params.slidesPerView)) {
                                    new Swiper('.product_associations_wrapper .swiper-container', asso_swiper_params);
                                }
                            }
                        }

                        // If there's not an associations_section working with a tabulation mechanism -> check carousel_wrapper
                        if (!$('.associations_section', $html_main).length) {
                            if ($('#carousel_wrapper', $html_main).length > 0) {
                                // For sites using the _app view, some sites don't want one particular swipers on reload so remove it from dom
                                if (typeof carouselException != 'undefined') {
                                    var new_carousel = $('#carousel_wrapper', $html_main);

                                    // Removing carousel exception from html_main response
                                    if ($('.ranges_carousel_wrapper.' + carouselException, new_carousel).length) {
                                        $('.ranges_carousel_wrapper.' + carouselException, new_carousel).remove();
                                    }

                                    $('#carousel_wrapper').html(new_carousel.html());
                                } else {
                                    $('#carousel_wrapper').html($('#carousel_wrapper', $html_main).html());
                                }

                                // Check if site has declared several associated swipers for its FP
                                if (typeof oAssociatedSwipers != 'undefined' && typeof oAssociatedSwipers == 'object') {

                                    // Loop and init swipers
                                    $.each(oAssociatedSwipers, function (container, params) {
                                        // Check if there are enough slides to init swiper
                                        if ($(container).length > 0) {
                                            if ($(container).find('.swiper-slide').length >= params.slidesPerView) {
                                                // Check if swiper-container is a sibling or a child
                                                setTimeout(
                                                    new Swiper($(container), params)
                                                , 500);
                                            }
                                        }
                                    })
                                } else {
                                    // If swipers are identical, init them the classic way
                                    if ($('#carousel_wrapper .swiper-container').length > 1) {
                                        $('#carousel_wrapper .swiper-container').each(function () {
                                            if ($(this).find('.swiper-slide').length >= asso_swiper_params.slidesPerView) {
                                                setTimeout(
                                                    new Swiper($(this), asso_swiper_params)
                                                , 500);
                                            }
                                        });
                                    } else if ($('#carousel_wrapper .swiper-container').length > 0) {
                                        if ($('#carousel_wrapper .swiper-container').find('.swiper-slide').length >= asso_swiper_params.slidesPerView) {
                                            setTimeout(
                                                new Swiper('#carousel_wrapper .swiper-container', asso_swiper_params)
                                            , 500);
                                        }
                                    }

                                }
                            } else {
                                $('#carousel_wrapper').html('');
                            }
                        }
                        if ($('#publication_page', $html_main).length > 0) {
                            $('#publication_page').html($('#publication_page', $html_main).html());
                        } else {
                            $('#publication_page').html('');
                        }

                        window.prod_slider = document.getElementById("product_pictures_slider");
                        window.asso_slider = document.getElementById('asso_product_carousel');
                        window.gamm_slider = document.getElementById('gamme_product_carousel');

                        /** Swiper function for gamme && assoc products
                         * TODO => Call it for assoc also
                         */
                        var swiperGamme = function (element, isCenteredSlides, isLoop) {
                            new Swiper(element, {
                                slidesPerView: 3,
                                centeredSlides: isCenteredSlides,
                                paginationClickable: true,
                                spaceBetween: 20,
                                pagination: '.swiper-pagination',
                                preloadImages: true,
                                lazyLoading: true,
                                loop: isLoop,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }

                        if (asso_slider && $('.swiper-slide', asso_slider).length > 0 && (typeof assoSwiperParams == "undefined")) {
                            var assoSwiperSlider = new Swiper('.swiper-container.asso_product_carousel', {
                                slidesPerView: 3,
                                centeredSlides: true,
                                paginationClickable: true,
                                spaceBetween: 20,
                                pagination: '.swiper-pagination',
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }

                        if (gamm_slider && $('.swiper-slide', gamm_slider).length > 0 && (typeof assoSwiperParams == "undefined")) {

                            if ($(window.gamm_slider).hasClass('noLoop')) {

                                /** Gamme swiper without loop => prevent products repetition bug */
                                gammSwiperSlider = swiperGamme('.swiper-container.gamme_product_carousel', false, false);
                            } else {

                                /** Default gamme swiper */
                                gammSwiperSlider = swiperGamme('.swiper-container.gamme_product_carousel', true, true);
                            }

                        }

                        /* Special case for a single view slider */
                        if ($('.assocSlider').length > 0 && $('.assocSlider .swiper-slide').length >= 1  && (typeof assoSwiperParams == 'undefined')) {
                            var assoSlider = new Swiper('.assoSlider', {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }

                        if ($('.gammeSlider').length > 0 && $('.gammeSlider .swiper-slide').length >= 1 && (typeof assoSwiperParams == "undefined")) {
                            var gammSlider = new Swiper('.gammeSlider', {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }

                        //set slider product
                        if (prod_slider && $(".swiper-slide", prod_slider).length > 1 ) {
                            initSwiperZoomContainer();
                        }


                        var string_params = '';
                        if ($("#produit_lot_nb").val() != undefined) {
                            string_params += '&produit_lot_nb=' + $("#produit_lot_nb").val();
                        }

                        if ($('#tailleProd').val() > 0) {
                            // RECHARGEMENT QTE
                            var qte = ajax_file(path_relative_root + 'ajax_liste_qte.php?idprod=' + datas.product.id + '&idcouleur=' + datas.product.color.id + '&idtaille=' + $('#tailleProd').val() + string_params);
                            $('#liste_qte').html(qte);
                        }

                        // If we have one size we directly check the stock of the product (cf VB)
                        if ($('input[name="itm_one_size"]').length && $('input[name="itm_one_size"]').val() === '1') {
                            onAttrSelect(parseFloat(datas.product.id));
                        }

                        var contentMovable = $('#content_movable');

                        $('.jqzoom').on('doubletap', function () {
                            zoomImg.call(this);
                            $('#zoomBox').addClass('active');

                            contentMovable.addClass('visible');
                            var zoomboxWidth = $('body').width(),
                                scrollTo = zoomboxWidth / 2;
                            setTimeout(function () {
                                $('#zoomBox').scrollLeft(scrollTo);
                            }, 'slow');
                        });

                        contentMovable.on('doubletap', function () {
                            $(this).removeClass('visible');
                            $('#zoomBox .btn_close').trigger('click');
                        });

                        // Moving element eclat (fiche produit) - WP-27657
                        if (($('.product_page').length) && ($('.site_contents_wrapper .product').length)) {
                            // Moving element Eclat Product only
                            $('#zoom_container #eclat_product').insertAfter('.prod_page_bot .price_container .price_tag');
                            
                            if ($('.price_tag.new_price').length === 0) {
                                // If the element with class 'price_tag' and 'new_price' does not exist
                                $('.paye_3').insertAfter('.price_container .price_tag').css({
                                    'margin-left' : 'auto',
                                });
                            } else if ($('.price_tag.new_price').length) {
                                // If the element with class 'price_tag' and 'new_price' exists
                                $('.paye_3').insertAfter('.price_container');
                            }
                            
                            $('#eclat_product_reduc').insertAfter('.price_tag.new_price');
                        }
                        
                        // var accordion_container = $('.product_page .accordion_container');
                        // var data_accordion =$('.accordion_container')
                        //check stock product
                        var hasStock = false;
                        var stocks = response.stock;
                        $.each(stocks, function(index, stock){
                            $.each(stock, function (key, val) {
                                if (val === true) {
                                    hasStock = true;
                                }
                            });
                        });

                        //si on a du stock, on affiche les éléments correspondant
                        if (hasStock) {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").show();
                            }
                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").show();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").hide();
                            }
                            //sinon, on les cache
                        } else {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").hide();
                            }
                            if ($("#add_basket_wrapper") !== undefined) {
                                // Hide btn when product is not available as it's done in product_form.php
                                $("#add_basket_wrapper").hide();
                            }
                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").hide();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                var option_selected = false;
                                if ($('#prod_taille').length) {
                                    $('#prod_taille option').each(function() {
                                        if($(this).is(':selected')) {
                                            option_selected = true;
                                            return option_selected;
                                        }
                                    });
                                }
                                if (option_selected) {
                                    $("#bloc_add_alert").show();
                                }
                            }
                        }
                    }
                    if ($('#oneProduct').length && $('#oneProduct').hasClass('actif')) {
                        $('div.item_container, .item_push').addClass('full');
                    }
                } else if (type_page == 'look') {

                    var $html_main = $(datas.main_content);

                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {
                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id, datas.product.id, path_relative_root);

                } else if (type_page == 'assoc') {

                    var $html_main = $(datas.html_main);
                    $('#ref_assoc_' + produit_principal).html($('#ref_assoc_' + produit_principal, $html_main).html());

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id, datas.product.id, path_relative_root);
                }

                // RECHARGEMENT STYLE INPUT
                $('.selectStyled').each(function () {
                    $(this).selectStyled();
                });

                if ($('#btn_add_alert', response.datas.html_main) && $('#btn_add_alert', response.datas.html_main).attr('onclick') && $('#btn_add_alert', response.datas.html_main).attr('onclick').indexOf('lightboxAlertStock') !== false) {
                    $(".eclat_product_nouveaute_fiche_produit").show();
                }

            } else {
                $('#btn_error').fadeIn();
            }

            if (document.getElementById("alma-widget") !== null) {
                initAlmaWidget();
            }
        },
        complete: function () {

            $('#bloc_btn_loader').fadeOut(300);

            if (window.lazyload) {
                lazyload.init();
            }

            var form = document.getElementById('item_add_form');
            if (!isProdKit) {
                if (is_achat_express_v2) {
                    form = $form;
                } else {
                    colors = form.itm_colour;

                    updateColor.call(colors.options[colors.selectedIndex]);
                }
            }

            setTimeout(function () { $(".zoom_indicator").fadeOut(800) }, 2500);
        }
    });
}

$(document).on('click', function() {
    setTimeout(function() {
        if ($('#zoom_container #eclat_product_reduc').length) {
            $('#zoom_container #eclat_product_reduc').insertAfter('#content_product .prod_page_bot .price_container .price_tag.new_price');
        }
    }, 2000);
});

function refreshSliderAchatExpress(id_swiper) {

    new Swiper('#' + id_swiper, {
        slidesPerView: 'auto',
        spaceBetween: 15,
    });
}

function addCard(id, from_tunnel){

    if( id>0 ){

        $("#connexion_card_txt").html('');
        $("#cmodbox").html('');

        $.ajax({
            type: 'post',
            data: { accept_cgv_card: 1, 'card_id': id, 'card_privilege_add' : true },
            url: path_relative_root+'ajax_add_card_to_cart.php',
            success: function( response ){

                try{
                    response = $.parseJSON( response );
                }
                catch( e ){
                    console.error( 'parseJSON' );
                    return;
                }
                var alt = response.alt;
                var image = '<img src="' + path_relative_root + 'img/compte/ma_carte/carte_popup_' + alt + '.jpg" alt="">';

                if( response.success == 'ok' ){
                    $("#modbox").addClass("carte_fid");
                    var amount = response.amount;
                    var name = response.name;
                    $("#visu_panier").remove();

                    if( !from_tunnel ){
                        var title = ajax_file( 'ajax_sprintf.php?arg1=produit_add_to_basket_desktop' );
                        $('#title_popup_panier').html(title);
                        $('.btn_to_basket').html(ajax_file( 'ajax_sprintf.php?arg1=go_to_cart_fid_uk' ));
                        $('.btn_to_shop').html(ajax_file( 'ajax_sprintf.php?arg1=continue_fid_uk' ));
                    }

                    var txt = '<div class="clearfix">';
                    txt = txt + '<p class="title_popup">'+ ajax_file( 'ajax_sprintf.php?arg1=item_added_to_cart' )+'</p>';
                    txt = txt + '<div class="f_left" id="visu_carte" style="">'+image+'</div>';
                    txt = txt + '<div id="txt_popup_carte" class="f_left">';
                    txt = txt + '<p class="prod_ajax_nom c_both bom_carte">' + name + '</p>';
                    txt = txt + '<p class="prod_ajax_prix c_both prix_carte">' + amount + '</p>';
                    txt = txt + '</div>';
                    txt = txt + '</div>';
                    $("#cmodbox").html(txt);
                    // Pour la lightbox du tunnel quand on ajoute une carte VIP au panier
                    $("#connexion_card_txt").html( txt );

                    var $html = $( response.html );
                    var $onObj = $('#cart_top');
                    $onObj.html($html);

                    $("#popup_mobile_panier_name").html(name);

                    if( from_tunnel ) {
                        txt = $(".btn_to_shop").html();
                        $(".btn_to_shop").remove();
                        $(".btn_to_basket").html(txt);
                    }

                    $("#txt_popup_panier").css('width','100%');
                    modBoxOpen.call(document.getElementById("lightbox_connexion_card"));

                }
                else{

                    var error = response.error;
                    if(response.noimage == 'no'){
                        $("#lightbox_connexion_card").addClass("carte_existante");
                        $("#connexion_card_txt").html( error );
                        $(".popup_carte_non_connecte_button a").attr("href", path_relative_root + create_link('order_basket'));
                    }
                    else{
                        $("#lightbox_connexion_card").removeClass("carte_existante");
                        $("#connexion_card_txt").html( image + '<br />' + error );
                        $(".popup_carte_non_connecte_button a").attr("href", path_relative_root + create_link('connexion_login') + '?from=fiche_carte');
                    }
                    modBoxOpen.call(document.getElementById("lightbox_connexion_card"));

                }

            }
        });
    }
}

function showAdvantageCard() {

    if( $('#lightbox_empty .content').html() == '' ) {

        $.ajax({
            type: 'post',
            url: path_relative_root+'ajax-carte-fidelite.html',
            success: function( response ){
                $('#lightbox_empty .content').html(response);
            }
        });

    }

    modBoxOpen.call(document.getElementById(lightbox_connexion_card));

}

/**
 * Peremt de récupérer l'id de la super_categorie, categorie, souscategorie ou objet selon le type_tri
 * @author Aurélien Renault
 * @since  2017-10-25
 * @return String
 */
function getCurrentIdRayon() {

    switch (document.getElementById('type_tri').value) {
        case 'obj':
            return document.getElementById('object_id').value;
        case 'cat':
            return document.getElementById('category_id').value;
        case 'supcat': // jamais utilisé
            return document.getElementById('category_super_id').value;
        case 'sscat':
        default:
            return document.getElementById('subcateg_id').value;
    }
}

function closepdv() {
    var shade = document.getElementById("shad");
    var modbox = document.getElementById("pdvBox");

    // Hide modbox and shade and destroy shade's click event
    $(modbox).slideUp(600, function () {
        $(shade)
            .off("click", closepdv)
            .stop(true, true)
            .fadeOut(200);
    });
}

// popup PDV
function showpdv() {
    var shad, modbox, contents;

    $.ajax({
        type: "post",
        url: path_relative_root + "ajax-distributeurs.php",
        success: function (res) {
            if (res) {
                shad = document.getElementById("shade");
                modbox = document.getElementById("pdvBox");

                // Make sure modbox will show on top of shad
                $(shad).after(modbox);

                // Inject store locator into modbox
                $(".store_locator").html(res);

                // magasin_gmap.js
                initialize();

                // Show modbox and make sure closing routine won't fire more than once
                $(shad)
                    .off("click", closepdv)
                    .fadeIn(200, function () {
                        $(modbox).slideDown(600);
                        $(shad).on("click", closepdv);
                    });
                $("html, body").animate({scrollTop: 0}, 500);
                $("#pdv_load").removeClass("loading");
            }
        },

    });

}

// Fonction pour l'ajout du produit boite cadeau
function addProdBoxGift(produit_id, panier_id) {

    if ($("#isGift").hasClass("checked") === false) {
        $("#isGift").addClass("checked");

        var productTitle = $("#isGift").data("product_add_basket_title");
        var productSize = $("#isGift").data("product_add_basket_size");
        var productColor = $("#isGift").data("product_add_basket_color");

        addToBasket(produit_id, '', false, panier_id, productTitle, productSize, null, productColor, null, null, 'panier', '', path_relative_root, null, null, true, 'gift');

    } else {
        $("#isGift").removeClass("checked");
    }
}
