
$( function () {
    $('#alma-widget').insertBefore('.bloc_question');
    
    if ($('.bandeauMenuAffix').length > 0) {
        $('body').addClass('hasBandeau');
    }
    

    // Header sticky
    var header = document.getElementsByClassName('banner_wrapper')[0];

    function onWinScroll() {
        var scrollTop = $(document).scrollTop();

        if (header.classList.contains("sticky")) {
            if (scrollTop === 0) {
                header.classList.remove("sticky");
                $("body").removeClass("header_sticky");
            }
        } else {
            if (scrollTop !== 0) {
                header.classList.add("sticky");
                $("body").addClass("header_sticky");
            }
        }
    }

    $(window).on("scroll", onWinScroll);
    onWinScroll();

    // HOME COLORS
    $('.open-color-list').click(function () {
        var colorListWrapper = $(this).parent().parent().find('.home-color-list-wrapper');
        colorListWrapper.toggleClass('minified'); // Target <article>
        if (colorListWrapper.hasClass('minified')) {
            $(this).find('.minified-color-list-text').show();
            $(this).find('.unminified-color-list-text').hide();
            colorListWrapper.find('.unminified').hide();
        } else {
            $(this).find('.minified-color-list-text').hide();
            $(this).find('.unminified-color-list-text').show();
            colorListWrapper.find( '.unminified' ).show();
            $(".open-color-list").click(function() {
                $('html, body').animate({
                    scrollTop: $(".home-color-list-wrapper").offset().top
                }, 1000);
            });
        }
    });
    
    // Wait for load_partials.js to be executed so that #site_head does not have its content refreshed after this code is executed
    $(window).on("accountLoad_complete", function() {
        $('#main_menu_btn').addClass('enabled');

        if ($('#sliderBando').length) {
            $('body').addClass('has_bando').removeClass('no_bando');
        } else {
            $('body').addClass('no_bando').removeClass('has_bando');
        }

        // Init Swiper bandeau
        if ($("#sliderBando .swiper-slide").length > 1) {
            var bandoHeadermobile = new Swiper("#sliderBando", {
                slidesPerView: 1,
                loop: true,
                speed: 1500,
                simulateTouch: true,
                autoplay: 5000,
                effect: "slide",
                direction: "horizontal",
                autoplayDisableOnInteraction: false,
                paginationClickable: true,
                preloadImages: true,
                lazyLoading: true,
            });
        }

        // Recherche
        if ( $( '#top_search' ).length ) {
            $( '#search_trigger' ).on( 'click touch', function () {
            if (!$( '#top_search' ).hasClass('show')) {
                    $( '#top_search' ).addClass('show');
                    $( '#search_home' ).focus();
                    $( '#shade' ).addClass('searching').show();
                } else {
                    $( '#top_search' ).removeClass('show');
                    $( '#search_home' ).blur();
                    $( '#shade' ).removeClass('searching').hide();
                }
            } );

            $( '#shade' ).on( 'click touch', function () {
                if ( $( '#shade' ).hasClass('searching')) {
                    $( '#top_search' ).removeClass('show');
                    $( '#search_home' ).blur();
                    $( '#shade' ).removeClass('searching').hide();
                }
            } );
        }

        // Menu
        if ($('#left_menu .main_menu_itm').length) {

            const toggleMenu = (trigger, target) => {
                trigger.on('click touch tap', function(event) {
                    event.preventDefault();
                    let currentTarget = $(this).next(target);
                    let isActive = currentTarget.hasClass('open');
        
                    trigger.not(this).removeClass('active');
                    $(".menu_part.bottom").find(target).not(currentTarget).slideUp().removeClass('open');
                    
                    $(this).toggleClass('active', !isActive);
                    currentTarget.slideToggle(!isActive).toggleClass('open', !isActive);
                });
            };
        
            if ($('#left_menu .main_menu_itm').length) {
                toggleMenu($('.menu_part.bottom .main_menu_itm .block_lnk'), '.submenu_wrapper');
                
                if ($('#left_menu .menu_subcategory.has_objects').length) {
                    toggleMenu($('.menu_subcategory.has_objects .ss_cat_name'), '.objet_cat');
                }
            }  

            $( '#main_menu_btn' ).on( 'click touch tap', function () {
                if ( $( '.wrap_rolloverproduit' ).length && $( '.productColorFieldset.actif' ).length ) {
                    setTimeout( function () {
                        $( '.productColorFieldset.actif' ).parent().parent().hide();
                        $( '.productColorFieldset.actif' ).removeClass('actif');
                    }, 100 );
                }
            });
        }

        // Switch langues menu
        // Debug on met un booleen en controleur pour le touchstart si true alors il est fait
        var touchController = false;

        $('#left_menu').on('click touchstart', '.cross_container', function(e) {
            if (e.type === "touchstart") {
                touchController = true; 
                // On va empêcher l'événement clic de se jouer (cause du doublon)
                e.preventDefault(); 
            } else if (e.type === "click" && touchController) {
                touchController = false; 
                return; 
                // on sort si clic avant touchstart
            }
        
            var $languesSwitcher = $('.switchLangWrapper .current .langues');
            $languesSwitcher.hasClass('open_lang') ? $languesSwitcher.removeClass('open_lang').slideUp() : $languesSwitcher.addClass('open_lang').slideDown();
        });
    });

    setTimeout(function() {
        // SEE MORE TEXT Multiple Bloc module
        $( ".home-module-cta-see-more" ).on( "click", function ( e ) {
            e.preventDefault();
            var seeBtn = $( ".home-module-cta-see-more" );
            var txt = $( ".home-module-subtitle:nth-child(3)" );

            if ( !$( this ).hasClass( 'open' ) ) {
                $( this ).addClass( 'open' );
                txt.addClass( 'active' );
                seeBtn.text( Translator.translate( 'home_see_less_txt' ) );
            } else {
                $( this ).removeClass( 'open' );
                txt.removeClass( 'active' );
                seeBtn.text( Translator.translate( 'home_see_more_txt' ) );
            }
        } );

        // Init swipers
        if ( $( ".satellite_univers_recyclage .swiperTg .swiper-slide" ).length > 1 ) {
            tgImageHomeSwiper = new Swiper(".swiperTg", {
                initialSlide: 1,
                slidesPerView: 'auto',
                nextButton: '.tg-module .swiper-button-next',
                prevButton: '.tg-module .swiper-button-prev',
            } );

            tgImageHomeSwiper.update();
        }

        if ($('.homepage .tg-module .swiperTg .swiper-slide').length > 0) {
            tgHomeSwiper =new Swiper('.tg-module .swiperTg', {
                slidesPerView: 1,
                slidesPerView: 'auto',
                spaceBetween: 8,
                nextButton: '.tg-module .swiper-button-next',
                prevButton: '.tg-module .swiper-button-prev',
            } );
        }

        if ( $( ".cms_page .cover_module_container .swiper-slide" ).length > 1 ) {
            cmsCoverSwiper = new Swiper(".cover_module_container", {
                autoplay: 5000,
                autoHeight : true,
            } );
        }

        // HP Slider Social Module
        if ($('.homepage .social-media-container .swiper-slide').length > 1) {
            hashtagHomeSwiper = new Swiper(".homepage .social-media-container", {
                autoplay: 5000,
                spaceBetween: 8,
                loop: false,
                slidesPerView: 2,
            });
        }

        if ( $( ".cms_page .cover_module_container .swiper-slide" ).length > 1 ) {
            cmsCoverSwiper = new Swiper(".cover_module_container", {
                autoplay: 5000,
            } );
        }
    }, 600);

    // Accordeon Footer
    if ($('.footer-links .col').length) {
        var title = $( '.footer-links .col_title' );

        // Reset footer
        $( '.footer-links .col_content' ).slideUp();
        $( '.footer-links .col' ).css('opacity', '1');

        title.each( function () {
            var links_content = $( this ).next( $( '.col_content' ) );

            $(this).on('click touch', function() {
                if (links_content.hasClass('show')) {
                    $(this).removeClass('open');
                    links_content.slideToggle();
                    links_content.removeClass('show');
                } else {
                    $('.footer-links .col_content').not(links_content).removeClass('show');
                    $('.footer-links .col_content').not(links_content).slideUp();
                    $('.footer-links .col_title').not($(this)).removeClass('open');
                    $(this).addClass('open');
                    links_content.slideToggle();
                    links_content.addClass('show');
                }
            });
        } );
    }

    // MON COMPTE SWIPER
    if ($('#menu_account_slider_mob .swiper-slide').length > 1) {
        var menuInitialSlide = $('#menu_account_slider_mob .swiper-slide.activeMenu').index();

        setTimeout(function() {
            new Swiper('#menu_account_slider_mob.swiper-container', {
                slidesPerView: 1,
                centeredSlides: true,
                initialSlide: menuInitialSlide,
            });
        }, 800);
    }

    // Move number of selected articles in wishlist
    if ( $('body').hasClass('wishlist') ) {
        $('#nrArticlesSelected').insertBefore('.wrapper_btn_top');
    }

    // Inits FP
    if ( $( 'body' ).hasClass( 'product_page' ) ) {
        // On execute les codes après l'ajax
        $( document ).ajaxComplete( function () {
            // On bouge la wishlist de la FP
            if ($('.prod_wishlist_btn').length) {
                $( '.prod_wishlist_btn' ).appendTo( $( '#product_name' ) );
            }

            // Initialisation des gondoles
            if ($('.assos_product .swiper-slide').length > 1) {
                new Swiper('.assoc_carousel_wrapper .assos_product', {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    loop: false,
                    nextButton: '.assos_product .assos-product-next',
                    prevButton: '.assos_product .assos-product-prev',
                });
            }

            if ($('.assos_rayon .swiper-slide').length) {
                new Swiper('.assoc_carousel_wrapper .assos_rayon', {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    loop: false,
                    nextButton: '.assos_rayon .assos-rayon-next',
                    prevButton: '.assos_rayon .assos-rayon-prev',
                });
            }

            // Affichage du header seulement si on passe les images
            setTimeout( function () {
                if ( $( '#zoom_container' ).length ) {
                    var elHeight = $( '#img_large' ).height();

                    if ($( window ).scrollTop() > elHeight) {
                        $( "#site_head" ).addClass('show_header');
                    } else {
                        $( "#site_head" ).removeClass('show_header');
                    }

                    $( window ).on( 'scroll', function () {
                        if ($( window ).scrollTop() > elHeight) {
                            $( "#site_head" ).addClass('show_header');
                        } else {
                            $( "#site_head" ).removeClass('show_header');
                        }
                    });
                }
            }, 500);
        });
    }

    // Ajout class lookbook sur body
    if ($('body').hasClass('category') && $('.lookbook').length) {
        $('body').addClass('lookbook');
    }

    // Lookbook
    if ( $( '.in_lookbook .swiper-container' ).length ) {
        $( '.in_lookbook .swiper-container' ).each( function () {
            if ( $( this ).find( '.swiper-slide' ).length > 1 ) {
                new Swiper($(this), {
                    slidesPerView: 'auto',
                    initialSlide: 1,
                });
            }
        } );
    }

    // Bloc seo
    if ($('.see_more_txt').length) {
        $( '.see_more_txt' ).on( 'click', function () {
            if (!$(this).hasClass('open')) {
                $( '.bloc_seo_description' ).addClass( 'active' );
                $( this ).addClass( 'open' );
                $( this ).html( Translator.translate( 'see_less' ) );
            } else {
                $( '.bloc_seo_description' ).removeClass( 'active' );
                $( this ).removeClass( 'open' );
                $( this ).html( Translator.translate( 'see_more' ) );
            }
        } );
    }

    // HP Cover module Swiper
    if ($('#coverSwiper .vimeo_video').length || $('#coverSwiper .youtube_video').length) {
        setTimeout(function() {
            var coverSwiper = new Swiper('#coverSwiper', {
                roundLengths: true,
                setWrapperSize: true,
                slidesPerView: 1,
                allowTouchMove: false
            });

            function initCoverSlider(target) {
                if ( $( '.swiper-slide-active .vimeo_video' ).length && $( '#coverSwiper .vimeo_video' ).attr( 'id' ) !== undefined ) {
                    var playerVimeo = $( '#coverSwiper .vimeo_video' ).length ? new Vimeo.Player( document.querySelector( '#coverSwiper .vimeo_video' ) ) : '';
                    
                    playerVimeo.setCurrentTime(0.0);
                    playerVimeo.play();
                    playerVimeo.getDuration().then(function(duration) {
                        setTimeout(function() { // Get next slide at the end of the video
                            if (target == "nextSlide") {
                                coverSwiper.slideNext();
                            } else {
                                coverSwiper.slideTo(0);
                            }
                        }, duration * 1000);
                    });
                } else if ($('.swiper-slide-active .youtube_video').length && $('#coverSwiper .youtube_video').attr('id') !== undefined) {
                    setTimeout(function() {
                        playerYT.playVideo();
    
                        var youTubeDuration = playerYT.getDuration() * 1000;
    
                        setTimeout(function() { // Get next slide at the end of the video
                            if (target == "nextSlide") {
                                coverSwiper.slideNext();
                            } else {
                                coverSwiper.slideTo(0);
                            }
                        }, youTubeDuration);
                    }, 1250);
                } else {
                    setTimeout(function() {
                        if (target == "nextSlide") {
                            coverSwiper.slideNext();
                        } else {
                            coverSwiper.slideTo(0);
                        }
                    }, 3000);
                }
            }

            coverSwiper.on('slideChangeEnd', function () {
                var nbrSlides = coverSwiper.slides.length;
                var lastIndex = coverSwiper.activeIndex;

                if (lastIndex == (nbrSlides - 1)) {
                    initCoverSlider("firstSlide");
                } else {
                    initCoverSlider("nextSlide");
                }
            });

            initCoverSlider("nextSlide");
        }, 600);
    } else {
        // If no video we keep old swiper initiation
        if ($("#coverSwiper .swiper-slide").length > 1) {
            coverModuleHomeSwiper = new Swiper("#coverSwiper:not(.no-swiper)", {
                loop: true,
                autoplay: 6000,
                slidesPerView: 1,
                nextButton: '.cover-module .cover-next',
                prevButton: '.cover-module .cover-prev',
            }, 500);
        }
    }

    initItemSlider();

    // Moving element eclat (fiche produit) - WP-27657
    if (($('.product_page').length)) {
        // Moving element Eclat Product only
        $('#zoom_container #eclat_product').insertAfter('.prod_page_bot .price_container .price_tag');

        if ($('.price_tag.new_price').length === 0) {
            // If the element with class 'price_tag' and 'new_price' does not exist
            $('.paye_3').css({
                'margin-left': 'auto',
            }).appendTo('.price_container');
        } else if ($('.price_tag.new_price').length) {
            // If the element with class 'price_tag' and 'new_price' exists
            $('.paye_3').insertAfter('.price_container');
        }
        
        $('#eclat_product_reduc').insertAfter('.price_tag.new_price');
    }

    // Moving element eclat (fiche produit cross sell) - WP-27657
    if ($('.product_page').length && $('.assoc_carousel_wrapper').length) {
        $('.assoc_carousel_wrapper li.swiper-slide').each(function() {
            let item = $(this).find('.eclat_rond');
            let newParent = $(this).find('.prix_site_gamme_assoc.price_tag.discounted');
            
            if (!newParent.length) {
                newParent = $(this).find('.prix_marche_gamme_assoc.price_tag');
            }

            item.appendTo(newParent);
        });
    }

    // Moving element eclat (homepage produit TG) - WP-27657
    if (($('.homepage').length) && ($('.site_contents_wrapper .home-columns-container').length)) {
        $('.swiper-wrapper li.swiper-slide').each(function() {
            let item = $(this).find('.eclat_gondole_home.eclat');
            let newParent = $(this).find('.wrap_price');
            item.appendTo(newParent);
        });
    }
    
    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
                $('.misc_cookiesinfo #shadCookie').removeClass('actif');
            }, 500);
        }
    });
    
    $('#traceBox .close_pop').click(function() {
        if($('#traceBox.lightbox').has('.actif')) {
            $('#traceBox.lightbox.actif').removeClass('actif');
        }

        if($('#shade.modal_shade.traceboxShad').has('.actif')) {
            $('#shade.modal_shade.traceboxShad.actif').removeClass('actif');
            $('#shade.modal_shade.traceboxShad').css('display', 'none');
        }
    });
});

/* You Tube API call */
if ($('#coverSwiper .youtube_video').length) {
    var YTtag = document.createElement('script');
    
    YTtag.src = "https://www.youtube.com/iframe_api";
    
    var YTfirstScriptTag = document.getElementsByTagName('script')[0];
    
    YTfirstScriptTag.parentNode.insertBefore(YTtag, YTfirstScriptTag);
    
    var YTiframeID = $('#coverSwiper .youtube_video').attr('id'); // Get the YT video iframe id
    var playerYT;
    
    function onYouTubeIframeAPIReady() {
        playerYT = new YT.Player(YTiframeID, {});
    }
}

/* Vimeo API call */
if ($('#coverSwiper .vimeo_video').length) {
    var Vimeotag = document.createElement('script');
    
    Vimeotag.src = "https://player.vimeo.com/api/player.js";
    
    var VimeofirstScriptTag = document.getElementsByTagName('script')[0];
    
    VimeofirstScriptTag.parentNode.insertBefore(Vimeotag, VimeofirstScriptTag);
}


function initAlmaWidgetBasket(amountBasket) {

    if (planAllowed.length && parseInt(amountBasket) != 0) {
        var widgets = Alma.Widgets.initialize(
            almaMerchantId, // ID marchand
            Alma.ApiMode[almaMerchantType], // mode de l'API (LIVE ou TEST)
        );

        widgets.add(Alma.Widgets.PaymentPlans, {
            container: '#alma-widget',
            purchaseAmount: parseFloat(amountBasket),
            locale: almaLang,
            hideIfNotEligible: almaHide,
            plans: planAllowed,
        });
        $('#alma-widget').insertBefore('.bloc_question');
    }
}


function basketPageUpdate(data) {

    var url = '';
	switch (data.type) {
		case 'quantity':
            url = create_link('order_basket')+'?action=del&panierId='+data.basketId+'&cad=non&pointcad=0&qteProd='+data.quantity+'&change_qte=true';
			break;
        case 'delete':
            url = create_link('order_basket')+'?action=del&panierId='+data.basketId+'&cad=non&pointcad=0&returnHtml=ajax';
            break;
        case 'update':
            url = create_link('order_basket');
            break;
	}

    return $.ajax({
        url: url,
        cache: false
    }).done(function(html) {
        try {
            html = $.parseJSON(html);

            if (typeof wShop !== 'undefined' && wShop.$refs.wCouponForm) {
                wShop.$refs.wCouponForm.reloadBloc+=1;
            }

        } catch( e ){
            console.error('parseJSON');
            return;
        }

        var datas = html.datas;
        var nbProdcut = [];
        var totalProducts = 0;

        setTimeout(function(){
            //Pour chacun des produits dans le panier, on récupère la quantité associée
            $('.wrap_dropdown_content .cart_item_qtty .quantity_selected').each(function() {
                nbProdcut.push(parseInt(this.innerText));
            });
            for (var i=0;i<nbProdcut.length;i++) {
                totalProducts += nbProdcut[i];
            }
            //On défini le nombre d'articles dans le panier avec le total des produits

            $('#cart_btn').attr('data-cart', totalProducts);
        },150);

        $('#contents_wrapper').html($(datas).not('#basket'));

        // Version 2
        var toggle_modal_actions = $('.toggle_modal_actions');
        if (toggle_modal_actions.length > 0) {
            toggle_modal_actions.click(function () {
                showModalAction($(this));
            });
            if (data.from === 'basket_action') {
                // On affiche l'ancien item action
            	if (data.basketIdNew !== undefined) {
                    $('#js-modal-action-' + data.basketIdNew).addClass('actif');
				} else {
                    $('#js-modal-action-' + data.basketId).addClass('actif');
				}
			}
        }

        if ($('#alma-widget').length > 0) {
            var amountBasket = $('.order_recap .order_total').attr('data-cartamount');
            $('#alma-widget').empty('');
            initAlmaWidgetBasket(amountBasket * 100);
        }

        var evt = new CustomEvent("changeQte_success");
        window.dispatchEvent(evt);
        $('#alma-widget').insertBefore('.bloc_question');
    });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_'+(multiTransp?idOrderTransp:idOrder);

    var has_errors = false;

    $(formId+" .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data =$('#return_products_form_' + idOrder).serialize();
        data += '&idOrder=' + (multiTransp?idOrderTransp:idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}
// Commande mobile end



function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var divId = 'popup_numero_retour_' + (multiTransp ? idOrderTransp : idOrder);

    var numDIVObjet = document.getElementById(divId);

    numDIVObjet.style.display = "block";
    numDIVObjet.style.opacity = "1";
    numDIVObjet.style.zIndex = "20";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = ".4";
    document.getElementById("general_shade").style.position = "fixed";
    document.getElementById("general_shade").style.zIndex = "15";
    document.getElementById("general_shade").style.left = "0";
    document.getElementById("general_shade").style.top = "0";
    document.getElementById("general_shade").style.width = "100vw";
    document.getElementById("general_shade").style.height = "100vh";
    document.getElementById("general_shade").style.backgroundColor = "black";

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);


    $("html, body").animate({scrollTop: 0}, (speed * 2), function () {
    });
}

function closeMultiShad(id) {
    // Lookbook ~ Fix error on lightbox close
    if (!$("#" + id).hasClass('lookbook_lightbox')) {
        modBoxClose();
    }

    $("#" + id).removeClass('actif');
    $("#" + id).fadeOut();
    $('#general_shade').fadeOut();
    $('#shade').fadeOut();

    if (!$("#traceBox").hasClass('actif')) {
        $("#shade").removeClass('traceboxShad');
    }
}

function close_popup(elem) {
    closeMultiShad(elem);
}

function openMultiShad(id) {
    $("#" + id).addClass('actif').show();
    $("#shade").addClass('actif').show();

    $("#shade").on("click", function () {
        closeMultiShad(id);
    } );

    if ($("#traceBox").hasClass('actif')) {
        $("#shade").addClass('traceboxShad');
    } else {
        $("#shade").removeClass('traceboxShad');
    }
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    } else {
        data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_create_etiquette'),
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function paginate(page) {
    var form = document.getElementById('filters_form'),
        total_element = parseFloat($('#totalElems').val()),
        nb_prod = parseFloat($('#nb_prod').val()),
        nb_total_page = Math.ceil(total_element / nb_prod),
        nb_prod_default = parseFloat($('#nb_prod_default').val()),
        pagination = $('.pagination'),
        see_all = parseFloat($('#see_all').val());

    page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {

            var productLoaded = $('#scroll_items .item_container:not(.push):not(.item_push)').length;
            var percentLoaded = (productLoaded * 100 / total_element);

            pagination.each(function () {
                if (see_all === 0) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product">' + Translator.translate('see_more_products') + '</div>';
                } else if (see_all === 1) {
                    this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }
            });
        }
    });
}

/**
 *Used to display the products
*/
function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || $('#type').val(),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || (form.getValue ? parseFloat($('#nb_prod').val()) : undefined),
        startPage = parseInt($('#page_start').val()),
        is_from_page = false,
        key,
        output = {};

    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form', fromPagination);
    nb_product = nb_product || parseFloat($('#nb_prod').val());

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
        is_from_page = true;
    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }
    // Add misc values to output object
    output.page = this.page.value;
    output.type = type;
    output.parentPath = path_relative_root;
    output.idObj = idObj;
    output.nb_prod = nb_product;
    output.is_page = is_from_page;
    output.nb_tot_prod = this.totalElems.value;

    if (typeof id_category_super != 'undefined') {
        output.id_category_super = id_category_super;
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    // If price exists
    if ($("input[name=price_min]").length > 0) {
        // Retrieving the price
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    if ($("input[name='tri']:checked").length > 0) {
        output.tri = $("input[name='tri']:checked").val();
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_reload_products.php',
        type: 'get',
        data: output,
        success: function (res) {
            var old_elm = document.querySelector('.list_item');
            var content, itm_length;
            var is_in_wishlist = [];

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {
                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();

            itm_length = parseFloat(res[1]);

            if ($('.rayonCategSlider').length && output.object_id) {

                $('.rayonCategSlider .menuitem').removeClass('actif');
                $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                var rayonInitialSlide;

                $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                    if ($(obj).hasClass('actif')) {
                        rayonInitialSlide = i;
                    }
                });

                if (typeof rayonCategSlider != 'undefined') {
                    rayonCategSlider.slideTo(rayonInitialSlide);
                }
            } else {
                $('.rayonCategSlider .menuitem').removeClass('actif');
            }

            if (content !== '') {
                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".loader_scroll").fadeTo("fast", 0);
                } else {
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //The opposite for the bottom
                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (one_product) {
                    $('div.item_container').addClass('full');
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {
                    toggleMenu('filters', 'right');
                }

                if (window.lazyload || typeof lazyload != 'undefined') {
                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }

                if (typeof generateNewBlocSearchExtra === 'function') {
                    generateNewBlocProdExtra();
                }

                // Display the number of products found and CTA if products are found
                $('.wrap_flitre_pager').show();
            } else {
                // Hide the number of products found and CTA if no products are found
                $('.wrap_flitre_pager').hide();
            }
        },
        complete: function () {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            if (!fromScrollBottom && !fromScrollTop) {
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: ($(window).scrollTop() + 10)
                    }, 200);
                }, 250);
            }

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Adding a min-height to the image block sections. This prevents the page flickering effect (due to lazy loading) when scrolling from the bottom.
                 */

                if ($('body.category').length) {
                    var origin = $('#scroll_items .item_container:not(.push):not(.item_push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();
                }
            }

            if (fromScrollBottom && !fromScrollTop) {
                $("#page_start").val(startPage + 1);

                if ($("#page_start").val() >= 1) {
                    $("#initial_page").val(page);
                }
            }

            setTimeout(function () {
                var loader = $('.button + .loader');
                if (loader.hasClass('loading')) {
                    loader.removeClass('loading');
                } else {
                    loader.parent().removeClass('loading');
                }

                if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {
                    var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                    toggleMenu(menuId);
                }
            }, 200);

            /**
             * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
             */
            var productLoaded = $('#scroll_items .item_container:not(.push):not(.item_push)').length;
            var percentLoaded = (productLoaded * 100 / parseInt(output.nb_tot_prod));

            if (productLoaded > output.nb_tot_prod) {
                $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + productLoaded);
            } else {
                $('.nbProdDisplayed').html(Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + parseInt(output.nb_tot_prod));
            }

            $('.txt_bandeau label').html('(' + output.nb_tot_prod + ' ' + Translator.translate('2_product') + ' )');

            $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

            $('.loader_scroll').removeClass('loading');

            if (productLoaded >= document.getElementById('totalElems').value) {
                $('.loader_scroll').hide();
                $('.see_all_product').hide();
            }

            initDailymotionVideos();
        }
    });
}

function generateNewBlocSearch(nb_product, page, btn, fromScrollBottom, fromScrollTop) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function (elm) {
        if ((elm.type === 'checkbox' || elm.type === 'radio')) {
            if (elm.checked && elm.value !== '') {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }
                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {
            if (elm.value !== '') {
                output[elm.name] = elm.value;
            }
        }
    });

    if (fromScrollTop || fromScrollBottom) {
        $(".loader_scroll").css({
            display: 'block'
        });
        $('#is_loading').val('1');
    }

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = output.page = page * nb_product;
        is_from_page = true;
    }

    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // If price exists
    if ($("input[name=price_min]").length > 0) {
        // Retrieving the price
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    $.ajax({
        url: path_relative_root + 'ajax_reload_search.php',
        type: 'get',
        data: output,
        success: function(res) {

            var new_elm = document.createElement('div'),
                old_elm = document.querySelector('.list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            if (content !== '') {

                if (!fromScrollBottom && !fromScrollTop) {
                    new_elm.className = 'list_item';
                    new_elm.innerHTML = content;
                    old_elm = document.querySelector('#scroll_items');
                    $(old_elm).find('list_item').remove();
                }

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".loader_scroll").show();
                } else {
                    backToTop();
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.appendChild(new_elm);
                }

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //The opposite for the bottom
                } else if (fromScrollBottom) {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {

                    toggleMenu('filters', 'right');
                }

                if (window.lazyload) {

                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            }
        },
        complete: function() {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');

            setTimeout(function() {
                var loader = $('.button + .loader');
                if (loader.hasClass('loading')) {
                    loader.removeClass('loading');
                } else {
                    loader.parent().removeClass('loading');
                }
                var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                    toggleMenu(menuId);
            }, 200);

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Adding a min-height to the image block sections. This prevents the page flickering effect (due to lazy loading) when scrolling from the bottom.
                 */

                if ($('body.category').length) {
                    var origin = $('.item_container:not(.push):not(.item_push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();

                    target.each(function() {
                        $(this).css({ minHeight: targetHeight });
                    })
                }
            }

            if (fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(page + 1);
                $(".loader_scroll").hide();
            }

            initDailymotionVideos();
        }
    });
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;
    var content_page = '';

    /**
     * Updating the origin page values for infinite scrolling
     */

    if ($('#scroll_origin').length) {
        if (!fromScrollBottom && fromScrollTop) {
            $('#scroll_origin').val(page);
        } else if (fromScrollBottom && !fromScrollTop) {
            $('#initial_page').val(page);
        } else {
            $('#scroll_origin, #initial_page').val(page);
        }
    }

    if (fromScrollBottom || fromScrollTop) {
        $(".loader_scroll").fadeTo("fast", 1);
    }

    if (from_type == 'product') {
        generateNewBlocProd(type, idObj, bloc_page, null, null, fromScrollBottom, fromScrollTop, true);
    } else {
        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}

/*FAQ*/
function faqinit(array_questions) {
    $(document).on('change keyup focus', '#faq_search', function () {
        $('#theme_page .theme').each(function() {
            $(this).removeClass('active');
        });

        showTabFaq();

        var search = $(this).val();

        if (search && search.length >= 3) {

            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').removeClass('actif');
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
                $('#theme_global').addClass('actif');
            }
        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').addClass('actif');
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        var retour = $('#reset_faq');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        $('#theme_global .theme').not($(this)).removeClass('actif');
        $(this).toggleClass('actif');
        retour.addClass('actif');
        theme.addClass('actif');
        theme.show();
        showTabFaq(id, 1)
    });

    $('#reset_faq').on('click', function () {
        var theme = $('#theme_page');
        var retour = $('#reset_faq');
        $('#theme_global').addClass('actif');
        theme.removeClass('actif');
        theme.hide();
        retour.removeClass('actif');
        showTabFaq();
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1);

        if (!$(this).hasClass('active')) {
            $('#theme_page .theme').each(function() {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });
}


function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp(
            "fast",
            function() {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass(
            "active"
        );
        $(".faq_question_" + question_id).toggleClass("active");

    } else {
        var parent_id = $(".faq_theme_" + node_id).data("parent");

        //active
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");

        //show questions
        $(
            ".faq_questions:not(.faq_questions_" +
            node_id +
            ", .faq_questions_" +
            parent_id +
            " )"
        ).slideUp("fast", function() {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function() {
            $(this).toggleClass("cache");
        });

        // Hide other node of same level
        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function(idx) {
                var id = $(this).data("id");
                $(".faq_level_parent_" + id).slideUp("fast", function() {
                    $(".faq_level_parent_" + id)
                        .addClass("cache")
                        .trigger("classChange");
                });
            }
        );

        // Show/hide child of selected node
        $(".faq_level_parent_" + node_id).slideToggle("slow", function() {
            $(".faq_level_parent_" + node_id)
                .toggleClass("cache")
                .trigger("classChange");
        });

        $(".faq_element").on("classChange", function() {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id)
                    .addClass("cache")
                    .trigger("classChange");
            }
        });

        $('html, body').animate({
            scrollTop: $("#theme_page").offset().top - 200
        }, 1000);
    }
}

$(document).ready(function (){
    $('#item_livraison').click(function (){
        setTimeout( function () {
            $('html, body').animate({
                scrollTop: $('#item_livraison').offset().top - 160
            }, 500);
        }, 500);
    });

    $('#item_sharing').click(function (){
        setTimeout( function () {
            $('html, body').animate({
                scrollTop: $('#item_sharing').offset().top - 160
            }, 500);
        }, 500);
    });

    $('#item_composition').click(function (){
        setTimeout( function () {
            $('html, body').animate({
                scrollTop: $('#item_composition').offset().top - 160
            }, 500);
        }, 500);
    });

    // Footer reassurance ~ Redirection sur question FAQ
    if ( $( 'body' ).hasClass( 'faq' ) ) {
        function Reassurancefaq() {
            // Reassurance livraison
            if (~window.location.href.indexOf("#livraison")
                || ~window.location.href.indexOf("#delivery")
                || ~window.location.href.indexOf("#segui_il_tuo_ordine")
                || ~window.location.href.indexOf("#levering")
                || ~window.location.href.indexOf("#consegna")) {
                $('#theme_page').addClass('actif');
                $( '.faq_theme_4' ).addClass( 'active' );
                $( '.faq_questions_4' ).removeClass('cache');
                $('.faq_question_14').addClass('active');
                $('.faq_reponse_14').show().removeClass('cache');

                $("html, body").delay(1000).animate({
                    scrollTop: $('.faq_element.active').offset().top - 175
                }, 1500);
            }

            // Reassurance retour
            if (~window.location.href.indexOf("#retour")
                || ~window.location.href.indexOf("#ritorno")
                || ~window.location.href.indexOf("#return")) {
                $('#theme_page').addClass('actif');
                $( '.faq_theme_5' ).addClass( 'active' );
                $( '.faq_questions_5' ).removeClass('cache');

                $("html, body").delay(1000).animate({
                    scrollTop: $('.faq_element.active').offset().top - 175
                }, 1500);
            }

            // Reassurance paiement
            if (~window.location.href.indexOf("#paiement")
                || ~window.location.href.indexOf("#pagamento")
                || ~window.location.href.indexOf("#betaling")) {
                $('#theme_page').addClass('actif');
                $( '.faq_theme_3' ).addClass( 'active' );
                $( '.faq_questions_3' ).removeClass('cache');
                $('.faq_question_29').addClass('active');
                $('.faq_reponse_29').show().removeClass('cache');

                $("html, body").delay(1000).animate({
                    scrollTop: $('.faq_element.active').offset().top - 175
                }, 1500);
            }
        }

        /* On click footer/reassurance */
        $('[href*="#pagamento"], [href*="#paiement"], [href*="#betaling"]').click(function() {
            $('#theme_page').addClass('actif');
            $('.faq_level_1').removeClass('active');
            $('.faq_questions').addClass('cache');
            $('.faq_question').removeClass('active');
            $('.faq_reponse').hide().addClass('cache');
            $('.faq_theme_3').addClass('active');
            $('.faq_questions_3').removeClass('cache');
            $('.faq_question_29').addClass('active');
            $('.faq_reponse_29').show().removeClass('cache');

            $("html, body").delay(1000).animate({
                scrollTop: $('.faq_element.active').offset().top - 175
            }, 1500);
        });

        $('[href*="#ritorno"], [href*="#retour"]').click(function() {
            $('.faq_level_1').removeClass('active');
            $('.faq_questions').addClass('cache');
            $('.faq_question').removeClass('active');
            $('.faq_reponse').hide().addClass('cache');
            $('#theme_page').addClass('actif');
            $('.faq_theme_5').addClass('active');
            $('.faq_questions_5').removeClass('cache');

            $("html, body").delay(1000).animate({
                scrollTop: $('.faq_element.active').offset().top - 175
            }, 1500);
        });

        $('[href*="#segui_il_tuo_ordine"], [href*="#enn_bestelling_volgen"]').click(function() {
            $('.faq_level_1').removeClass('active');
            $('.faq_questions').addClass('cache');
            $('.faq_question').removeClass('active');
            $('.faq_reponse').hide().addClass('cache');
            $('#theme_page').addClass('actif');
            $('.faq_theme_4').addClass('active');
            $('.faq_questions_4').removeClass('cache');
            $('.faq_question_14').addClass('active');
            $('.faq_reponse_14').show().removeClass('cache');

            $("html, body").delay(1000).animate({
                scrollTop: $('.faq_element.active').offset().top - 175
            }, 1500);
        });

        $('[href*="#consegna"], [href*="#livraison"], [href*="#levering"]').click(function() {
            $('.faq_level_1').removeClass('active');
            $('.faq_questions').addClass('cache');
            $('.faq_question').removeClass('active');
            $('.faq_reponse').hide().addClass('cache');
            $('#theme_page').addClass('actif');
            $('.faq_theme_4').addClass('active');
            $('.faq_questions_4').removeClass('cache');

            $("html, body").delay(1000).animate({
                scrollTop: $('.faq_element.active').offset().top - 175
            }, 1500);
        });

        setTimeout(function() {
            Reassurancefaq();
        }, 500);
    }
});

$('body.faq .faq_reponse table').each(function() {
    $(this).wrap('<div class="block-table"></div>');
});

// VARIABLE E-RESA
var eresa_v2 = true;

//
$(function() {
    if ($("#e-resaBox") && $('body').hasClass('en')) {
        $(document).ajaxComplete(function () {
            setTimeout(function(){
                var arrayTxt = $('.en .schedule_store').html();
                $('.en .schedule_store').empty().html(arrayTxt.replace(' et ',' and '));
            }, 1500);
        });
    }

    // Move in JS to avoid overriding product view - WP-26035
    if($('#estimated_delivery').length && $('#add_basket_wrapper').length) {
        $('#estimated_delivery').insertBefore('#add_basket_wrapper');
    }

    // Move in JS to avoid overriding product view - WP-26035
    if($('.ariane_retour_wrapper').length && $('#contents_wrapper').length) {
        $('.ariane_retour_wrapper').insertAfter('#contents_wrapper');
        $('.ariane_retour_wrapper').css('display', 'block');
    }

    // Homepage - footer: inscription NL
    if ($('.bloc-news-footer .w-newsletter-form .w-email-input').length) {
        var target = $('.bloc-news-footer .w-newsletter-form .w-email-input')
        var inputField = $(".bloc-news-footer .w-newsletter-form .w-input.w-email-input input");

        inputField.on('click tap touch', function() {
            setTimeout(function () {
                $('.bloc-news-footer .w-newsletter-form').addClass('w-form-is-focus');
            }, 150);
        });

        inputField.on('focusout', function() {
            setTimeout(function () {
                $('.bloc-news-footer .w-newsletter-form').removeClass('w-form-is-focus');
            }, 150);
        });

        inputField.on('focusout', function() {
            if ($('.bloc-news-footer .w-newsletter-form').hasClass('w-form-is-focus') && target.hasClass('w-nonempty')) {
                setTimeout(function () {
                    $('.bloc-news-footer .w-newsletter-form').addClass('w-form-is-focus');
                }, 150);
            }
        });

        inputField.on('focusout', function() {
            if ($('.bloc-news-footer .w-newsletter-form').hasClass('w-form-is-focus') && !inputField.val() && target.hasClass('w-nonempty') && target.hasClass('w-has-error') && target.hasClass('w-focused')) {
                setTimeout(function () {
                    $('.bloc-news-footer .w-newsletter-form').removeClass('w-form-is-focus');
                }, 150);
            }
        });
    };
    
    if ($('body').hasClass('store_locator')) {
        var schedulesArg = document.querySelectorAll('.satellite_store_details .bloc_horraire .time');
        changeSymbolSchedule(schedulesArg);
    }
});

window.addEventListener('load', function() {

    var products = document.querySelectorAll('.item_container ');
    var j = 1;
    var checkedProductIds = new Set();

    for (var i = 0; i < products.length; i++) {

        if (!products[i] || !products[i].getAttribute('id')) {
            continue;
        }
        var productId = products[i].getAttribute('id').split('i')[1];

        if (checkedProductIds.has(productId)) {
            continue;
        }
        
        checkedProductIds.add(productId);

        if (typeof checkProductInWishlist === "function") {
            checkProductInWishlist(productId, function(productExistsInWishlist) {
                if (productExistsInWishlist) {
                    products[i].classList.add('existToWishlistButton');
                }
            });
        }
        j++;
    }
});

function getWishlistProducts () {
    let array_elements = (document.querySelectorAll('button[data-productid]').length > 0 ) ? document.querySelectorAll('button[data-productid]') : document.querySelectorAll('option[data-produitid]');
    let array_ids = [];

    if (typeof array_elements != 'undefined' && array_elements.length > 0) {
        for (el of array_elements) {
            var id = (el.getAttribute('data-productid') !== null) ? el.getAttribute('data-productid') : el.getAttribute('data-produitid');

            if (id != '' && id != 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));

        $.ajax({
            type: 'get',
            url: path_relative_root + create_link("get_user_all_wishlist"),
            data: data,
            success: function (response) {

                if (response) {
                    if (typeof response != 'undefined' && response.length > 0) {
                        let array_product_id = JSON.parse(response);
                        for (product_wishlist in array_product_id) {
                            $('button[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("existToWishlistButton");
                            //$('button[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').removeClass("addToWishlistButton");
                            $('button[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').attr('data-wishlistproductid', array_product_id[product_wishlist].wishlist_product_id);
                            $('#addToWishlistButton').addClass("existToWishlistButton");}
                    }
                }
            }
        });
    }
}

window.onload = function(){
    getWishlistProducts();
    if($('.product_page').length) {
        initSwiperReassuranceProduct();
    }
}

function remove_product_from_wishlist(id, callback) {
    $.post(path_relative_root + create_link("ajax_remove_from_wishlist"), { id: id }, callback);
}

function ajax_RemoveWishlistProd(id) {
    remove_product_from_wishlist(id, function(data) {

        if (data.result) {
            var wishlistLength = data.result.wishlistLength;
            $('#wishlist_list .remove_top_wishlist[data-id=' + id + ']').parents('.product-link').remove();
            if (wishlistLength < 1) {
                if ($('#show_top_wish .rollover_empty').length) {
                    $('#show_top_wish .rollover_empty').remove()
                } else {
                    $('#wishlist_top .title_panier').remove();
                    $('#wishlist_top .wrap_tot_panier').remove();
                    $('#wishlist_top #wishlist_list').remove();
                    $('#wishlist_top .title_panier .title_wishlist_rollover .nb_products').remove();
                }
                $('#wishlist_top #show_top_wish').addClass('noprod');
                if ($("#show_top_wish.noprod>.wrap_title").length) {
                    $("#show_top_wish.noprod>.wrap_title").remove();
                }
                var newtxt = '<div class="rollover_empty"><div class="title_panier title">'+ Translator.translate('my_wishlist') +'</div><div class="no_products">' + Translator.translate('your_wishlist_empty') + '</div></div>';
                $('#wishlist_top #show_top_wish').append(newtxt);
            } else {
                $('#wishlist_top .title_panier .title_wishlist_rollover .nb_products').text('(' + (wishlistLength));
                $("#wishlist_top .cart_product_listing").overlayScrollbars({});
            }
        }
    });
}

if ($('body.category #wrapper_visus_produit .list_item #push_rayon_2').length) {
    $('.subTitleRayon').addClass('pushRayon'); 
}

function initSwiperReassuranceProduct() {
    if ($("#swiper_reassurance_product").length) {
        new Swiper('#swiper_reassurance_product', {
            autoplay: 3500,
            pagination: '#swiper_reassurance_product .swiper-pagination',
            loop: true,
            slidesPerView: 1,
            allowTouchMove: true,
            autoplayDisableOnInteraction: false,
            paginationClickable: true,
            simulateTouch: true,
        });
    }
}

function initSwiperZoomContainer() {
    if ($('#zoom_container .swiper-slide').length) {
        // add id in swiper-container
        $('#zoom_container .swiper-container').attr('id', 'swiper_zoom_container');
        new Swiper( '#swiper_zoom_container', {
            pagination: '#swiper_zoom_container .swiper-pagination',
            loop: true,
            slidesPerView: 1,
            allowTouchMove: true,
            autoplayDisableOnInteraction: false,
            paginationClickable: true,
            simulateTouch: true,
        } );

        $('#zoom_container .swiper-container .swiper-button-next, #zoom_container .swiper-container .swiper-button-prev').remove();
    }
}

function reloadProductBlocNoResults() {
    var content = '<div class="no_results_wrapper">' +
                    '<div class="search_details_wrapper">' +
                        '<div class="no_result_block">' +
                            Translator.translate('no_result_txt') +
                        '</div>' +
                    '</div>' +
                '</div>';
    $('#scroll_items .list_item').html(content);
}

/* STORE LOC */
// Creation d'un bloc correspondant a un marqueur sur la liste laterale
function creerLigneListe(cpt, id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng, horaire_plus) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement('a');
    li.id = id;

    li.className = 'elem-list-store';

    if (lat != "" && lng != "" && !$('body.product_page').length) {
        li.setAttribute("href", url);
    }


    /***** Product stock infos *****/
    if (stock_quantity == 'undefined' || stock_quantity == '') {
        stock_status_msg = 'unknow_stock';
    } else {
        var in_stock = (stock_quantity >= 6 && stock_quantity <= 9999);
        var limited_stock = (stock_quantity >= 1 && stock_quantity <= 5);
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = '';
        if (in_stock) {
            stock_status_msg = 'in_stock';
        }
        if (limited_stock) {
            stock_status_msg = 'limited_stock';
        }
        if (not_in_stock) {
            stock_status_msg = 'not_in_stock';
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = 'to_be_confirmed_stock';
        }
        if (can_be_ordered_stock) {
            stock_status_msg = 'can_be_ordered_stock';
        }
    }

    var info_horaire_today = !horaire_today ? Translator.translate("store_closed") : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_closed';
    var ligneListe =
        '<div class="elem_list_contents">' +
        '<div class="nom_store">';
        if (distance) {
            ligneListe += '<h2 class="title">' + nom +  '<p class="distance">' + '('+ distance + 'km)' + '</p></h2>';
        } else {
            ligneListe += '<h2 class="title">' + nom + '</h2>';
        }
        ligneListe += '</div>' +
        '<div class="content_store">'
        
        if ($('body.product_page').length) {
            ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url}'">`
        } else {
            ligneListe += '<div class="detail_store">'
        }
        ligneListe += '<div class="col-1">' +
        '<span class="rue_store">' + adresse.toLowerCase() + '</span>' +
        '<span class="ville_store">' + cp + ' ' + ville.charAt(0).toUpperCase() + ville.slice(1).toLowerCase() + ' - ' + '<span class="pays_store">' + pays + '</span>' + '</span>' +
        '<span class="tel_store">' + telephone + '</span></div>';


    //for (var i = 0; i < picture_filtre.length; i++) {
    //	ligneListe += '<div class="filtre_store"><img src="' + path_relative_root + picture_filtre[i] + '" /></div>';
    //}

    var is_page_product = $('body.product_page').length == 1;

    if ((info_horaire_today !== '' && currently_open) || (!currently_open && horaire_today)) {
        ligneListe += '<div class="col-2"><p class="horaire_today puce_' + class_horaire_today + '">' + info_horaire_today + '</p></div>';
    }

    if (horaire_plus != '') {
        ligneListe += '<div class="horaire_plus">' + horaire_plus + '</div>';
    }

    ligneListe += '</div>';
    if( document.getElementById('produit_id') ) {

        /***** Product stock infos msg *****/
        if (stock != 'undefined') {
            ligneListe += '<p class="stock_status ' + stock_status_msg + '">' + Translator.translate(stock_status_msg + '_msg') + '</p>';
        }
    }
    if (is_page_product) {

        if (beContacted == 1) {
            if ($('body.product_page.mobile').length) {
                ligneListe += '<div class="availableTxt store_more" onclick="handleChangeMobile('+ id +')">' + '<span>' + txt_available_mag + '</span>' + '</div>' + '</div>' + '</div>';
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe += '<div class="availableTxt store_more" onclick="handleChange(\''+ id +'\',\''+ nom_mag + '\')">' + '<span>' + txt_available_mag + '</span>' + '</div>' + '</div>' + '</div>';
            }
        } else {
            ligneListe+= '</div>' + '</div>';
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="createDataLayer(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('store_more') + '</a>'
        + '</div>';
    }

    ligneListe+= '</div>' + '</div>' + '</div>';

    li.innerHTML = ligneListe;

    li.addEventListener('click', function () {
        google.maps.event.trigger(marqueur, 'click');
    });


    return li;
}

function creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image_mag, type_mag, cpt, magasin_id, url, distance, horaire_today, currently_open) {

    var img = path_relative_root + "img/marker-39.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(img,
        // This marker is 11 pixels wide by 14 pixels tall.
        new google.maps.Size(11, 14),
        // The origin for this image is 0,0.
        new google.maps.Point(0,0));

        // The anchor for this image is the base of the flagpole at 0,32.
        //new google.maps.Point(10, 26));
        var shadow = new google.maps.MarkerImage(path_relative_root + 'img/indic_carte_ombre.png',
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0,0),
        new google.maps.Point(10,0));
        // Shapes define the clickable region of the icon.
        // The type defines an HTML <area> element 'poly' which
        // traces out a polygon as a series of X,Y points. The final
        // coordinate closes the poly by connecting to the first
        // coordinate.

    var marqueur = new google.maps.Marker({
        position: point,
        icon: image
    });
    
    marqueur.set("magasin_id", magasin_id);

    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? '' : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_close';
    var currently_open_info = currently_open ? Translator.translate('store_opened') : Translator.translate('store_closed');


    var cpt_recherche = ( typeof(cpt) != 'undefined' )? '('+cpt+') - ':'';

    if(distance) {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a><span class="dist">(' + distance + ' km)</span>';
    } else {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a></div>';
    }

    if( image_mag != '' ) 
        ligneListe += '<img src="' + image_mag + '" />';

    ligneListe += `<div class="adresse">
        <span class="adresse">${adresse}</span>
        <br>
        <span class="cp">${cp}</span> <span class="ville">${ville}</span>
        <br>

    </div>`;

    if (telephone != '')
        ligneListe += '<div class="coord">' + telephone + '</div>';

    /* if (email != '')
        ligneListe += '<div class="coord">Email : ' + email + '</div>'; */

    if (site != '')
        if(!http.test(site)){
            ligneListe += '<a href="http://' + site + '" class="site" target="_blank">' + site + '</a>';
        }else{
            ligneListe += '<a href="' + site + '" class="site" target="_blank">' + site + '</a>';
        }

    if (horaire != '')
        if(class_horaire_today == 'store_open'){
            ligneListe += '<div class="horaire_title"><span class = "'+class_horaire_today+'">'+ currently_open_info + '</span>' + info_horaire_today +'</div><div class="horaire_info">' + horaire + '</div>';
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe += '<div class="horaire_title"><span class = "'+class_horaire_today+'">'+ currently_open_info + '</span></div><div class="horaire_info">' + horaire + '</div>';
        }

        ligneListe += '<div class="btn_marker_container">';
        ligneListe += '<div class="btn_voir_magasin"><a href="' + url + '">' + Translator.translate('store_more') + '</a></div>';
        ligneListe += '<div class="btn_aller_magasin"><a href="https://maps.google.fr/maps?daddr=' + point.lat() + ',' + point.lng() + '">' + Translator.translate('go_to_store') + '</a></div>';  
        ligneListe += '</div></div>';

    google.maps.event.addListener(marqueur, "click", function() {
        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });
    
    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id
    
    markersArray.push(marqueur);
    
    return marqueur;
}

// make the show more shops button dissapear once every .elem-list-store is shown via the .show-store class
$("body").on("click", "#showMore, #showAll", function() {
    var allStores = document.querySelectorAll('.elem-list-store');
    var allStoresShown = document.querySelectorAll('.elem-list-store.show-store');
    
    if (allStores.length == allStoresShown.length) {
        $("#showMore, #showAll").hide();
    }
});

function changeSymbolSchedule(schedulesArg) {
    var body = document.body;
    var replacementSymbol = body.classList.contains('fr') ? 'h' : ':';
    schedules = schedulesArg;
    schedules.forEach(scheduleElement => {
        if (replacementSymbol === ':') {

            scheduleElement.innerHTML = scheduleElement.innerHTML.replace(/(\d)h(\d)/g, `$1${replacementSymbol}$2`);
        }
    });
}


function checkProductInWishlist() {
    if (typeof button_add_to_wishlist_initial_click_event == "undefined") {
        button_add_to_wishlist_initial_click_event = $(
            "#addToWishlistButton"
        ).attr("onclick");
    }
    if (typeof button_add_to_wishlist_initial_label == "undefined") {
        button_add_to_wishlist_initial_label = $("#addToWishlistButton").html();
    }

    let productId = $("#produit_id").val();
    let checkColor = $("#prod_couleur option:selected");
    let checkSize = $("#prod_taille");
    let colorSelected = checkColor.length ? checkColor.val() : "";
    let sizeSelected = checkSize.length ? checkSize.val() : "";

    if (
        typeof productId != "undefined" &&
        colorSelected !== "" &&
        sizeSelected !== ""
    ) {
        $.ajax({
            type: "get",
            url: path_relative_root + "get_user_wishlist.php",
            data: {
                product_id: productId,
                size_id: sizeSelected,
                color_id: colorSelected,
            },
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            },
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

function changeBtnAddWishlist(in_wishlist) {
    $("#addToWishlistButton").attr(
        "onclick",
        button_add_to_wishlist_initial_click_event
    );

    if (in_wishlist) {
        $("span", "#addToWishlistButton").html(
            Translator.translate("exist_to_wishlist_button_label")
        );
        $("#addToWishlistButton")
            .removeClass("addToWishlistButton")
            .addClass("existToWishlistButton");
        $("#addToWishlistButton").removeClass("heart").addClass("heart_b");
    } else {
        $("span", "#addToWishlistButton").html(
            Translator.translate("wishlist")
        );
        $("#addToWishlistButton")
            .removeClass("existToWishlistButton")
            .addClass("addToWishlistButton");
        $("#addToWishlistButton").removeClass("heart_b").addClass("heart");
    }
}

/**
 * Global .item_container slider initialization
 * isToggled => is true when from a toggle between 1 or 2 products per line (function defined in _app)
 */
function initItemSlider(isToggled) {
    if ($('.prodItemSlider, .searchItemSlider').length) {
        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php
        if (isToggled == true) { // Toggle view is triggerred
            setTimeout(function () {
                $('.prodItemSlider, .searchItemSlider').each(function () {
                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll
            $(".item_container").each(function (e) {
                var this_item_rayon = $(this).attr('id');
                if (!$(this).find('.prodItemSlider').hasClass('initialized') && $(this).find('.prodItemSlider .swiper-slide').length > 1) {
                    var swiper_rayon_mobile_each = new Swiper('#' + this_item_rayon + ' .prodItemSlider', {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: '#' + this_item_rayon + ' .itemSliderPagination',
                    });
                    $(this).find('.prodItemSlider').addClass('initialized');
                }
            });
        }
    }
}

/* Load global .item_container slider initialization on window scroll*/
window.addEventListener("scroll", function () {
    initItemSlider(false);
});

/* Initialisation des JS pour la recherche V2 */
function triggerFunctionOnSearchEnd() {
    //Init des swiper dans le cas des visuel multi des produit (MOBILE_MULTI_VISUALS)
    initItemSlider(false);
}

function openColorAchatExpressV2() {
    var $div_color = $(this.form).find(".productColorFieldset");
    var $div_size = $(this.form).find(".productSizeFieldset");
    var $div_alert_stock = $(this.form).find(".bloc_add_alert");
    var lightbox = $(this.form).find(".achat_express");

    $(this.form).find(".noSizeAvailable").hide();

    /* Hiding previous selections */
    $div_alert_stock.hide();
    $div_size.removeClass("actif");

    $div_color.addClass("actif");
    lightbox.show();
}

$(window).on("showQteListeGamme_complete", function (data) {

    let param = {
        produit_id : data.detail.productId,
        product_color : data.detail.productColor,
    };

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_selection_offert.php',
        data: param,
        success: function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success && res.stock > 0) {
                if ($('.ligne_produit_offert.seleted .wrapper_info_prod')) {

                    if (res.title !== '') {
                        $('.ligne_produit_offert.seleted .wrapper_info_prod .product_title').html(res.title);
                    }

                    if (res.subtitle !== '') {
                        $('.ligne_produit_offert.seleted .wrapper_info_prod .product_subtitle').html(res.subtitle);
                    }
                }
            }
        }
    });
});

function changeSeoElementWithPagination(res) {
    // This block of code updates the SEO-related link elements in the HTML document.
    let canonical = res[5];
    let prev = res[6];
    let next = res[7];

    $("link[rel^='canonical']").attr("href", path_root + canonical);
    $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);

    $("link[rel^='alternate'][hreflang^='en-en']").attr("href", JSON.parse(res[8]).en);
    $("link[rel^='alternate'][hreflang^='de-de']").attr("href", JSON.parse(res[8]).de);
    $("link[rel^='alternate'][hreflang^='it-it']").attr("href", JSON.parse(res[8]).it);
    $("link[rel^='alternate'][hreflang^='nl-nl']").attr("href", JSON.parse(res[8]).nl);

    let hrefPrev = $("link[rel^='prev']");
    let hrefNext = $("link[rel^='next']");
    let commentPrev = $('#href_prev');
    let commentNext = $('#href_next');

    changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
    changeSeoElement(next, hrefNext, commentNext, 'next');
}
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });

    initDailymotionVideos();
});